var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-user" },
    [
      _c(
        "el-form",
        { attrs: { inline: true, "label-position": "right" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入编号",
                          clearable: "",
                          size: "mini",
                        },
                        model: {
                          value: _vm.queryParams.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "number", $$v)
                          },
                          expression: "queryParams.number",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入姓名",
                          clearable: "",
                          size: "mini",
                        },
                        model: {
                          value: _vm.queryParams.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "name", $$v)
                          },
                          expression: "queryParams.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("treeselect", {
                        attrs: {
                          options: _vm.deptOptions,
                          multiple: false,
                          flat: false,
                          "show-count": true,
                          "default-expand-level": 1,
                          placeholder: "归属部门",
                          size: "mini",
                        },
                        model: {
                          value: _vm.queryParams.agencyDeptId,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "agencyDeptId", $$v)
                          },
                          expression: "queryParams.agencyDeptId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "values" } },
                    [
                      _c("treeselect", {
                        attrs: {
                          options: _vm.roleOptions,
                          multiple: false,
                          flat: false,
                          "show-count": true,
                          "default-expand-level": 1,
                          "disable-branch-nodes": true,
                          placeholder: "所属角色",
                          size: "mini",
                        },
                        model: {
                          value: _vm.queryParams.agencyRoleId,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "agencyRoleId", $$v)
                          },
                          expression: "queryParams.agencyRoleId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "绑定状态",
                            clearable: "",
                            size: "mini",
                          },
                          model: {
                            value: _vm.queryParams.bindStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "bindStatus", $$v)
                            },
                            expression: "queryParams.bindStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "未绑定", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "已绑定", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                        plain: "",
                      },
                      on: { click: _vm.onHandleQuery },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.onResetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "fl",
          staticStyle: {
            "text-align": "right",
            "margin-bottom": "20px",
            "margin-top": "10px",
          },
          attrs: { gutter: 10 },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.onHandleAdd },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    icon: "el-icon-upload",
                    size: "mini",
                  },
                  on: { click: _vm.onHandleImportClick },
                },
                [_vm._v("批量导入")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    icon: "el-icon-upload",
                    size: "mini",
                  },
                  on: { click: _vm.onHandleExportClick },
                },
                [_vm._v("批量导出")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-delete", size: "mini" },
                  on: { click: _vm.onHandleDelete },
                },
                [_vm._v("全部删除")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-delete",
                    size: "mini",
                    disabled:
                      !_vm.selectedUserIds || _vm.selectedUserIds.length == 0,
                  },
                  on: { click: _vm.onHandleDeleteBatch },
                },
                [_vm._v("批量删除")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "table",
          attrs: {
            data: _vm.agencyUserList,
            "cell-class-name": "no-right-border",
            "header-cell-class-name": "no-right-border",
            border: "",
          },
          on: {
            "selection-change": _vm.handleSelectionChange,
            "cell-mouse-enter": _vm.onEnterTable,
            "cell-mouse-leave": _vm.onLeaveTable,
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { prop: "number", label: "编号", width: "100px" },
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "姓名", width: "140px" },
          }),
          _c("el-table-column", {
            attrs: { prop: "username", label: "登录账号", width: "140px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.username ? scope.row.username : "--")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "dept",
              "min-width": "100px",
              label: "部门",
              align: "left",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._l(
                      scope.row.agencyUserDeptNames,
                      function (deptItem, deptIndex) {
                        return scope.row.agencyUserDeptNames &&
                          scope.row.agencyUserDeptNames.length > 0
                          ? [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "line-height": "20px",
                                    height: "20px",
                                    overflow: "hidden",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.deptFullName(
                                        scope.row.agencyUserDeptIds[deptIndex]
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e()
                      }
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "role",
              "min-width": "80px",
              label: "角色",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.agencyUserRoleNames &&
                      scope.row.agencyUserRoleNames.length > 0
                      ? [
                          _vm._l(
                            scope.row.agencyUserRoleNames,
                            function (roleItem, roleIndex) {
                              return [
                                _c("span", [_vm._v(_vm._s(roleItem))]),
                                roleIndex <
                                scope.row.agencyUserRoleNames.length - 1
                                  ? _c("span", [_vm._v("、")])
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c("el-table-column", {
            attrs: { prop: "type", "min-width": "120px", label: "用户身份" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == "1"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              height: "20px",
                              "line-height": "20px",
                              overflow: "hidden",
                            },
                          },
                          [_vm._v("机构管理员")]
                        )
                      : scope.row.type == "2"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              height: "20px",
                              "line-height": "20px",
                              overflow: "hidden",
                            },
                          },
                          [_vm._v("机构普通用户")]
                        )
                      : scope.row.type == "3"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              height: "20px",
                              "line-height": "20px",
                              overflow: "hidden",
                            },
                          },
                          [_vm._v("机构子管理员")]
                        )
                      : _c(
                          "span",
                          {
                            staticStyle: {
                              height: "20px",
                              "line-height": "20px",
                              overflow: "hidden",
                            },
                          },
                          [_vm._v("--")]
                        ),
                  ]
                },
              },
            ]),
          }),
          _vm._l(_vm.userExtendProfile, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                prop: "type",
                "min-width": "100px",
                label: item.options.label,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.extendFieldMap
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.extendFieldMap[item.options.label]
                                  ) +
                                  " "
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: { prop: "date", width: "80px", label: "绑定状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.bindStatus == "1" ? "未绑定" : "已绑定"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "",
              fixed: "right",
              align: "right",
              width: "210px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onHandleDetailClick(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onHandleUpdateClick(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    scope.row.bindStatus === "1"
                      ? _c(
                          "el-button",
                          {
                            key: scope.row.id + "1",
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showAddAgencyUserDialog(scope.row)
                              },
                            },
                          },
                          [_vm._v("绑定")]
                        )
                      : _vm._e(),
                    scope.row.bindStatus !== "1"
                      ? _c(
                          "el-button",
                          {
                            key: scope.row.id + "2",
                            attrs: {
                              size: "mini",
                              type: "text",
                              disabled: true,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showAddAgencyUserDialog(scope.row)
                              },
                            },
                          },
                          [_vm._v("绑定")]
                        )
                      : _vm._e(),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-dropdown",
                      {
                        staticStyle: { float: "right" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "dropdown",
                              fn: function () {
                                return [
                                  _c(
                                    "el-dropdown-menu",
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          scope.row.bindStatus !== "1"
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    status: "primary",
                                                    content: "更改手机号",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeAgencyUserMobile(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("更改手机号")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: { color: "#ED6363" },
                                              attrs: {
                                                size: "mini",
                                                type: "text",
                                                vif: "scope.row.type=='2'",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onHandleDeleteClick(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "el-dropdown-link" },
                          [
                            _c(
                              "el-button",
                              { attrs: { type: "text", size: "mini" } },
                              [
                                _vm._v("更多... "),
                                _c(
                                  "el-icon",
                                  { staticClass: "el-icon--right" },
                                  [_c("arrow-down")],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getAgencyUserList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "600px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
            close: _vm.onCloseDialogClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户编号", prop: "number" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入用户编号",
                              disabled: _vm.isDetail,
                            },
                            model: {
                              value: _vm.form.number,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "number", $$v)
                              },
                              expression: "form.number",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户名称", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入用户名称",
                              disabled: _vm.isDetail,
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "归属部门", prop: "agencyDeptIds" } },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.deptOptions,
                              multiple: true,
                              "show-count": true,
                              flat: true,
                              "default-expand-level": 1,
                              placeholder: "请选择归属部门",
                              disabled: _vm.isDetail,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "value-label",
                                fn: function (ref) {
                                  var node = ref.node
                                  return _c("div", {}, [
                                    _vm._v(_vm._s(node.raw.fullName)),
                                  ])
                                },
                              },
                            ]),
                            model: {
                              value: _vm.form.agencyDeptIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "agencyDeptIds", $$v)
                              },
                              expression: "form.agencyDeptIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属角色", prop: "agencyRoleIds" } },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.roleOptions,
                              multiple: true,
                              "show-count": true,
                              flat: true,
                              "default-expand-level": 1,
                              "disable-branch-nodes": true,
                              placeholder: "请选择所属角色",
                              disabled: _vm.isDetail,
                            },
                            model: {
                              value: _vm.form.agencyRoleIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "agencyRoleIds", $$v)
                              },
                              expression: "form.agencyRoleIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "user-extend-fields",
              staticStyle: { "padding-left": "12px" },
            },
            [
              _vm.open
                ? _c("v-form-render", {
                    ref: "vformRender",
                    attrs: {
                      formJson: _vm.formJson,
                      formData: _vm.formData,
                      optionData: _vm.optionData,
                      previewState: false,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          !_vm.isDetail
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onSubmitFormClick },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c("el-button", { on: { click: _vm.onCancelClick } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "绑定系统用户",
            visible: _vm.openUserBindAdd,
            width: "600px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openUserBindAdd = $event
            },
            close: _vm.onCloseBindingDialogClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "bindUserForm",
              attrs: {
                model: _vm.bindUserForm,
                rules: _vm.agencyUserRules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户编号", prop: "number" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入用户编号" },
                            model: {
                              value: _vm.bindUserForm.number,
                              callback: function ($$v) {
                                _vm.$set(_vm.bindUserForm, "number", $$v)
                              },
                              expression: "bindUserForm.number",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号码", prop: "mobile" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入用户名称" },
                            model: {
                              value: _vm.bindUserForm.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.bindUserForm, "mobile", $$v)
                              },
                              expression: "bindUserForm.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户名称", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入用户名称" },
                            model: {
                              value: _vm.bindUserForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.bindUserForm, "name", $$v)
                              },
                              expression: "bindUserForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveBindingUserDialog },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { on: { click: _vm.onCloseBindingDialogClick } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "更改绑定手机号",
            visible: _vm.openUserMobileChange,
            width: "600px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openUserMobileChange = $event
            },
            close: _vm.onCloseUserMobileChangeClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "bindUserMobile",
              attrs: {
                model: _vm.bindUserMobile,
                rules: _vm.changeUserMobileRules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号码", prop: "mobile" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入手机号码" },
                            model: {
                              value: _vm.bindUserMobile.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.bindUserMobile, "mobile", $$v)
                              },
                              expression: "bindUserMobile.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveUserMobileChangeDialog },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { on: { click: _vm.onCloseUserMobileChangeClick } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingVisible,
              expression: "loadingVisible",
            },
          ],
          attrs: {
            title: _vm.upload.title,
            visible: _vm.upload.open,
            width: "600px",
            "append-to-body": true,
            "element-loading-text": _vm.progressText,
            "element-loading-background": "rgba(50,50,50, 0.6)",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.upload, "open", $event)
            },
            close: _vm.onCloseUploadDialogClick,
          },
        },
        [
          _c("el-alert", { attrs: { type: "warning", closable: false } }, [
            _vm._v("已存在的用户也可进行数据导入，系统会进行更新处理。"),
          ]),
          _c(
            "el-upload",
            {
              ref: "upload",
              attrs: {
                limit: 1,
                accept: ".xlsx, .xls",
                headers: _vm.upload.headers,
                action: "",
                disabled: _vm.upload.isUploading,
                "on-progress": _vm.handleFileUploadProgress,
                "on-success": _vm.handleFileSuccess,
                "http-request": _vm.handleFileRequest,
                drag: "",
              },
              model: {
                value: _vm.upload.file,
                callback: function ($$v) {
                  _vm.$set(_vm.upload, "file", $$v)
                },
                expression: "upload.file",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  staticStyle: { "margin-top": "5px", "line-height": "16px" },
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "font-size": "12px" },
                      attrs: { type: "text" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { download: "机构用户导入模板.xlsx" },
                          on: { click: _vm.downloadTemplate },
                        },
                        [_vm._v("下载模板")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      staticStyle: {
                        color: "red",
                        display: "inline-block",
                        "margin-top": "0",
                        "margin-left": "5px",
                      },
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("提示：仅允许导入“xls”或“xlsx”格式文件！")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmUploadClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelUploadClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }