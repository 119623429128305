<template>
  <div class="app-user">
    <el-form :inline="true" label-position="right">
      <el-row>
        <el-col :span="4">
          <el-form-item label="">
            <el-input v-model="queryParams.number" placeholder="请输入编号" clearable size="mini" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="">
            <el-input v-model="queryParams.name" placeholder="请输入姓名" clearable size="mini" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="">
            <treeselect v-model="queryParams.agencyDeptId" :options="deptOptions" :multiple="false" :flat="false" :show-count="true" :default-expand-level="1" placeholder="归属部门" size="mini" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="" prop="values">
            <treeselect v-model="queryParams.agencyRoleId" :options="roleOptions" :multiple="false" :flat="false" :show-count="true" :default-expand-level="1" :disable-branch-nodes="true" placeholder="所属角色" size="mini" />
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="">
            <el-select v-model="queryParams.bindStatus" placeholder="绑定状态" clearable size="mini">
              <el-option label="未绑定" value="1"></el-option>
              <el-option label="已绑定" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-form-item>
          <el-button class="filter-item" type="primary" icon="el-icon-search" size="mini" @click="onHandleQuery" plain>搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="onResetQuery">重置</el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <el-row :gutter="10" class="fl" style="text-align: right; margin-bottom: 20px; margin-top: 10px">
      <el-col :span="1.5">
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="onHandleAdd">新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="warning" icon="el-icon-upload" size="mini" @click="onHandleImportClick">批量导入</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="warning" icon="el-icon-upload" size="mini" @click="onHandleExportClick">批量导出</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button icon="el-icon-delete" size="mini" @click="onHandleDelete">全部删除</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button icon="el-icon-delete" size="mini" :disabled="!selectedUserIds||selectedUserIds.length==0" @click="onHandleDeleteBatch">批量删除</el-button>
      </el-col>
    </el-row>
    <el-table :data="agencyUserList" ref="table" @selection-change="handleSelectionChange" @cell-mouse-enter="onEnterTable" @cell-mouse-leave="onLeaveTable" cell-class-name="no-right-border" header-cell-class-name="no-right-border" border>
      <el-table-column type="selection" width="55">
      </el-table-column>
      <el-table-column prop="number" label="编号" width="100px"></el-table-column>
      <el-table-column prop="name" label="姓名" width="140px"></el-table-column>
      <el-table-column prop="username" label="登录账号" width="140px">
        <template slot-scope="scope">
          <span>{{scope.row.username ? scope.row.username : '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dept" min-width="100px" label="部门" align="left" show-overflow-tooltip>
        <template slot-scope="scope">
          <template v-for="(deptItem, deptIndex) in scope.row.agencyUserDeptNames" v-if="scope.row.agencyUserDeptNames && scope.row.agencyUserDeptNames.length > 0">
            <div style="line-height:20px;height:20px;overflow:hidden">{{deptFullName(scope.row.agencyUserDeptIds[deptIndex])}}</div>
            <!-- <span>{{deptItem}} </span>
                        <span v-if="deptIndex < scope.row.agencyUserDeptNames.length - 1">、</span> -->
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="role" min-width="80px" label="角色" show-overflow-tooltip>
        <template slot-scope="scope" v-if="scope.row.agencyUserRoleNames && scope.row.agencyUserRoleNames.length > 0">
          <template v-for="(roleItem,roleIndex) in scope.row.agencyUserRoleNames">
            <span>{{roleItem}}</span>
            <span v-if="roleIndex < scope.row.agencyUserRoleNames.length - 1">、</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="type" min-width="120px" label="用户身份">
        <template slot-scope="scope">
          <span style="height:20px;line-height:20px;overflow:hidden" v-if="scope.row.type=='1'">机构管理员</span>
          <span style="height:20px;line-height:20px;overflow:hidden" v-else-if="scope.row.type=='2'">机构普通用户</span>
          <span style="height:20px;line-height:20px;overflow:hidden" v-else-if="scope.row.type=='3'">机构子管理员</span>
          <span style="height:20px;line-height:20px;overflow:hidden" v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="type" min-width="100px" :label="item.options.label" v-for="(item,index) in userExtendProfile" :key="index">
        <template slot-scope="scope">
          <template v-if="scope.row.extendFieldMap">
            {{scope.row.extendFieldMap[item.options.label]}}
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="date" width="80px" label="绑定状态">
        <template slot-scope="scope">
          <span>{{scope.row.bindStatus == '1' ? '未绑定' : '已绑定'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="" fixed="right" align="right" width="210px">
        <template slot-scope="scope" vif="scope.row.showBtn">
          <el-button size="mini" type="text" @click="onHandleDetailClick(scope.row)">查看</el-button>

          <el-divider direction="vertical"></el-divider>
          <el-button size="mini" type="text" @click="onHandleUpdateClick(scope.row)">修改</el-button>

          <el-divider direction="vertical"></el-divider>
          <el-button size="mini" type="text" :key="scope.row.id+'1'" v-if="scope.row.bindStatus==='1'" @click="showAddAgencyUserDialog(scope.row)">绑定</el-button>
          <el-button size="mini" type="text" :key="scope.row.id+'2'" v-if="scope.row.bindStatus!=='1'" :disabled="true" @click="showAddAgencyUserDialog(scope.row)">绑定</el-button>

          <!-- <el-button size="mini" type="text" v-if="scope.row.bindStatus!=='1'" @click="changeAgencyUserMobile(scope.row)">更改手机号</el-button> -->
          <!-- <el-button size="mini" type="text" v-if="scope.row.bindStatus!=='1'" @click="unBindAgencyUser(scope.row)">解绑</el-button> -->

          <!-- <el-divider direction="vertical" v-if="scope.row.type=='2'"></el-divider> -->

          <el-divider direction="vertical"></el-divider>
          <el-dropdown style="float:right">
            <span class="el-dropdown-link">
              <el-button type="text" size="mini">更多...
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </el-button>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <el-button type="text" status="primary" content="更改手机号" v-if="scope.row.bindStatus!=='1'" @click="changeAgencyUserMobile(scope.row)">更改手机号</el-button>
                </el-dropdown-item>

                <el-dropdown-item>
                  <el-button size="mini" type="text" style="color: #ED6363" vif="scope.row.type=='2'" @click="onHandleDeleteClick(scope.row)">删除</el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <!-- 解绑：保留机构用户信息，只是不绑定对应系统账号id(user_id) -->
          <!-- 删除：不保留机构用户信息，也是包含解绑的 -->
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total>0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getAgencyUserList" />

    <!--新增、修改、查看机构用户-->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body @close="onCloseDialogClick" :close-on-click-modal="false">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户编号" prop="number">
              <el-input v-model="form.number" placeholder="请输入用户编号" :disabled="isDetail" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入用户名称" :disabled="isDetail" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="归属部门" prop="agencyDeptIds">
              <treeselect v-model="form.agencyDeptIds" :options="deptOptions" :multiple="true" :show-count="true" :flat="true" :default-expand-level="1" placeholder="请选择归属部门" :disabled="isDetail">
                <div slot="value-label" slot-scope="{ node }">{{ node.raw.fullName }}</div>
              </treeselect>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="所属角色" prop="agencyRoleIds">
              <treeselect v-model="form.agencyRoleIds" :options="roleOptions" :multiple="true" :show-count="true" :flat="true" :default-expand-level="1" :disable-branch-nodes="true" placeholder="请选择所属角色" :disabled="isDetail" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="user-extend-fields" style="padding-left:12px;">
        <v-form-render ref="vformRender" v-if="open" :formJson="formJson" :formData="formData" :optionData="optionData" :previewState="false"></v-form-render>
      </div>
      <div slot="footer" class="dialog-footer" v-if="!isDetail">
        <el-button type="primary" @click="onSubmitFormClick">确 定</el-button>
        <el-button @click="onCancelClick">取 消</el-button>
      </div>
    </el-dialog>

    <!--绑定系统用户-->
    <el-dialog title="绑定系统用户" :visible.sync="openUserBindAdd" width="600px" append-to-body @close="onCloseBindingDialogClick" :close-on-click-modal="false">
      <el-form ref="bindUserForm" :model="bindUserForm" :rules="agencyUserRules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户编号" prop="number">
              <el-input v-model="bindUserForm.number" placeholder="请输入用户编号" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="手机号码" prop="mobile">
              <el-input v-model="bindUserForm.mobile" placeholder="请输入用户名称" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户名称" prop="name">
              <el-input v-model="bindUserForm.name" placeholder="请输入用户名称" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveBindingUserDialog">确 定</el-button>
        <el-button @click="onCloseBindingDialogClick">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 更改绑定手机号 el-dialog -->
    <el-dialog title="更改绑定手机号" :visible.sync="openUserMobileChange" width="600px" append-to-body @close="onCloseUserMobileChangeClick" :close-on-click-modal="false">
      <el-form ref="bindUserMobile" :model="bindUserMobile" :rules="changeUserMobileRules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="手机号码" prop="mobile">
              <el-input v-model="bindUserMobile.mobile" placeholder="请输入手机号码" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveUserMobileChangeDialog">确 定</el-button>
        <el-button @click="onCloseUserMobileChangeClick">取 消</el-button>
      </div>
    </el-dialog>

    <!--用户导入对话框-->
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="600px" :append-to-body="true" @close="onCloseUploadDialogClick" v-loading="loadingVisible" :element-loading-text="progressText" element-loading-background="rgba(50,50,50, 0.6)">
      <el-alert type="warning" :closable="false">已存在的用户也可进行数据导入，系统会进行更新处理。</el-alert>

      <el-upload ref="upload" :limit="1" accept=".xlsx, .xls" :headers="upload.headers" action="" v-model="upload.file" :disabled="upload.isUploading" :on-progress="handleFileUploadProgress" :on-success="handleFileSuccess" :http-request="handleFileRequest" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip" style="margin-top: 5px; line-height: 16px;">
          <!-- <template v-if="env.NODE_ENV=='production'">
            <el-button type="text" style="font-size: 12px;"><a href='https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/%E6%9C%BA%E6%9E%84%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx' download="机构用户导入模板.xlsx">下载模板</a></el-button>
          </template>
          <template v-else>
            <el-button type="text" style="font-size: 12px;"><a href=' https://smart-form-dev.oss-cn-beijing.aliyuncs.com/statics/%E6%9C%BA%E6%9E%84%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx' download="机构用户导入模板.xlsx">下载模板</a></el-button>
          </template> -->
          <el-button type="text" style="font-size: 12px;"><a @click="downloadTemplate" download="机构用户导入模板.xlsx">下载模板</a></el-button>
          <!--          <el-link type="info" style="font-size:12px" href='https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/%E6%9C%BA%E6%9E%84%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx' download="机构用户导入模板.xlsx">下载模板</el-link>-->
          <div class="el-upload__tip" style="color:red; display: inline-block; margin-top: 0; margin-left: 5px;" slot="tip">提示：仅允许导入“xls”或“xlsx”格式文件！</div>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmUploadClick">确 定</el-button>
        <el-button @click="onCancelUploadClick">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { agencyDeptList, agencyRoleList, updateAgencyUser, deleteAgencyUser, batchDeleteAgencyUser, agencyUserList, agencyUserExcelImport, downloadUserExcel, getAgency, downloadImportTemplate, agencyUserExcelImportProgress, agencyUserBinding, agencyUserUnBinding, agencyUserChangeBinding } from '@/api/system/agency'
import { userExtendFieldFormJson } from '@/api/system/user'
import { getSessionStorageObj, setSessionStorageObj } from '@/utils/db'
import { getOssSts } from '@/api/tool/oss'
import { deepClone } from '@/utils/deepClone.js'

export default {
  name: 'User',
  components: { Treeselect },
  props: {
    cur: {
      type: String | Number
    }
  },
  data () {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        bindStatus: '',
        name: '',
        number: ''
      },
      total: 0, // 总页数
      agencyUserList: [],
      form: {},
      rules: {
        number: [{ required: true, message: '用户编号不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '用户名称不能为空', trigger: 'blur' }]
      },
      agencyUserRules: {
        number: [{ required: true, message: '用户编号不能为空', trigger: 'blur' }],
        mobile: [{ required: true, message: '手机号码不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '用户名称不能为空', trigger: 'blur' }]
      },
      changeUserMobileRules: {
        mobile: [{ required: true, message: '手机号码不能为空', trigger: 'blur' }]
      },
      open: false,
      openUserBindAdd: false, //弹出添加机构用户的窗口
      title: '',
      deptList: [],//部门列表
      deptOptions: [], // 部门树形
      deptTreeProps: {
        children: 'children',
        label: 'deptName'
      },
      roleOptions: [], // 角色树形
      roleTreeProps: {
        children: 'children',
        label: 'roleName'
      },
      isDetail: false, // 是否查看table每一项的详情
      // 用户导入参数
      upload: {
        open: false,
        title: '',
        isUploading: false, // 是否禁用上传
        file: null
      },
      env: process.env,

      showTable: false,
      formJson: {},
      formData: {},
      optionData: {},
      userExtendProfile: [],
      selectedUserIds: [],
      loadingVisible: false,
      progressText: '导入中，请稍候',
      bindUserForm: {
        name: "",
        number: "",
        mobile: "",
        agencyId: 0
      },
      bindUserMobile: {
        mobile: "",
        agencyUserId: 0
      },
      openUserBindAdd: false,
      openUserMobileChange: false,

    }
  },
  computed: {},
  created () {
    this.getAgencyUserList()
    this.getDeptTreeSelect() // 部门
    this.getRoleTreeSelect() // 角色
    this.getUserExtendField() //获取用户的扩展字段

  },
  methods: {
    // 搜索
    onHandleQuery () {
      this.getAgencyUserList()
    },
    // 重置
    onResetQuery () {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        bindStatus: '',
        name: '',
        number: ''
      }
      this.deptOptions = []
      this.roleOptions = []
      this.getAgencyUserList()
      this.getDeptTreeSelect()
      this.getRoleTreeSelect()
    },
    // 新增用户
    onHandleAdd () {
      this.resetForm()
      this.open = true
      this.title = '添加用户'
      this.isDetail = false;
      this.getDeptTreeSelect() // 部门
      this.getRoleTreeSelect() // 角色


      this.setUserExtendFormJson(true);
    },
    onHandleDelete () {
      const delUser = () => {
        deleteAgencyUser('delete-all')
          .then(() => {
            this.msgSuccess('用户删除成功')
            this.getAgencyUserList()
          })
          .catch(() => {
            this.msgError('用户删除失败')
          })
      }
      this.$confirm('确实要删除全部用户吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delUser()
      })
    },
    resetForm () {
      this.form = {
        name: '',
        number: ''
      }
      if (this.$refs['form'] !== undefined) {
        this.$refs['form'].resetFields()
        this.$refs['form'].clearValidate()
      }
    },
    getDeptTreeSelect () {
      agencyDeptList().then((response) => {
        const treeList = response.data
        this.deptList = treeList;
        this.deptOptions = this.handleTree(treeList, 'agencyDeptId')
        this.deptOptions = this.getDeptTree(null, this.deptOptions)
      })
    },
    getDeptTree (parent, tree = []) {
      const arr = []
      if (tree.length !== 0) {
        tree.forEach((item) => {
          const obj = {}
          obj.label = item.deptName,
            obj.fullName = (parent ? parent.fullName + '-' : '') + item.deptName,
            obj.id = item.agencyDeptId

          item.fullName = obj.fullName;
          if (item.children) {
            obj.children = this.getDeptTree(item, item.children)
          }
          arr.push(obj)
        })
      }
      return arr
    },
    getRoleTreeSelect () {
      agencyRoleList().then((response) => {
        const treeList = response.data
        this.roleOptions = this.handleTree(treeList, 'agencyRoleId')
        this.roleOptions = this.getRoleTree(this.roleOptions)
      })
    },
    getRoleTree (tree = []) {
      const arr = []
      if (tree.length !== 0) {
        tree.forEach((item) => {
          const obj = {}
          obj.label = item.roleName
          obj.id = item.agencyRoleId
          if (item.children) {
            obj.children = this.getRoleTree(item.children)
          }
          arr.push(obj)
        })
      }
      return arr
    },
    onSubmitFormClick () {
      this.$refs['form'].validate((valid) => {
        if (valid) {

          this.$refs.vformRender.getFormData().then((vformData) => {

            const agencyUserInsertVo = {}
            agencyUserInsertVo.name = this.form.name
            agencyUserInsertVo.number = this.form.number
            const agencyDeptIds = [] // 部门ids
            const agencyRoleIds = [] // 角色ids
            if (!this.isBlank(this.form.agencyDeptIds)) {
              for (let i = 0; i < this.form.agencyDeptIds.length; i++) {
                agencyDeptIds.push(this.form.agencyDeptIds[i])
              }
              agencyUserInsertVo.agencyDeptIds = agencyDeptIds
            }
            if (!this.isBlank(this.form.agencyRoleIds)) {
              for (let i = 0; i < this.form.agencyRoleIds.length; i++) {
                agencyRoleIds.push(this.form.agencyRoleIds[i])
              }
              agencyUserInsertVo.agencyRoleIds = agencyRoleIds
            }
            if (!this.isBlank(this.form.agencyUserId)) {
              agencyUserInsertVo.agencyUserId = this.form.agencyUserId
            }
            const self = this

            agencyUserInsertVo.extendField = vformData;
            updateAgencyUser(agencyUserInsertVo).then((response) => {
              if (response.code == 200) {
                const msg = !this.isBlank(agencyUserInsertVo.agencyUserId) ? '修改成功' : '新增成功'
                self.msgSuccess(msg)
                self.open = false
                self.getAgencyUserList()
                this.$store.dispatch("GetCurrentAgencyUser").then((res) => { });
              } else {
                this.msgError(response.msg)
              }
            })
          })
        }
      })
    },
    onCancelClick () {
      this.resetForm()
      this.open = false
    },
    onCloseDialogClick () {
      this.resetForm()
    },
    getAgencyUserList () {
      agencyUserList(this.queryParams).then((response) => {
        if (response.code == 200) {
          for (let i = 0; i < response.rows.length; i++) {
            response.rows[i].showBtn = false
          }
          this.agencyUserList.splice(0);
          response.rows.forEach(r => {
            this.agencyUserList.push(r)
          })
          this.total = response.total
        } else {
          this.msgError(response.msg)
        }
      })
    },
    onEnterTable (row, column, cell, event) {
      row.showBtn = true
      this.$forceUpdate()
    },
    onLeaveTable (row, column, cell, event) {
      row.showBtn = false
      this.$forceUpdate()
    },
    // 修改机构用户
    async onHandleUpdateClick (row) {
      this.isDetail = false
      this.resetForm()
      this.open = true
      this.title = '修改用户'
      this.getDeptTreeSelect() // 部门
      this.getRoleTreeSelect() // 角色
      this.form.name = row.name
      this.form.number = row.number
      if (!this.isBlank(row.agencyUserDeptIds)) {
        this.form.agencyDeptIds = row.agencyUserDeptIds
      }
      if (!this.isBlank(row.agencyUserRoleIds)) {
        this.form.agencyRoleIds = row.agencyUserRoleIds
      }
      this.form.agencyUserId = row.agencyUserId

      await this.setUserExtendFormJson(true);
      await this.setUserExtendFormData(row);

    },
    // 删除机构用户
    onHandleDeleteClick (row) {
      const delUser = () => {
        deleteAgencyUser(row.agencyUserId)
          .then(() => {
            this.msgSuccess('用户删除成功')
            let idx = this.agencyUserList.findIndex(x => x.agencyUserId == row.agencyUserId)
            this.agencyUserList.splice(idx, 1);
          })
          .catch(() => {
            this.msgError('用户删除失败')
          })
      }
      if (row.bindStatus == 2) {
        this.$confirm('该用户已绑定是否确认删除？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delUser()
        })
      } else {
        this.$confirm('确实要删除此用户吗？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delUser()
        })
      }
    },
    // 查看用户详情
    async onHandleDetailClick (row) {
      this.isDetail = true
      this.resetForm()
      this.open = true
      this.title = '查看用户'
      this.getDeptTreeSelect() // 部门
      this.getRoleTreeSelect() // 角色
      this.form.name = row.name
      this.form.number = row.number
      if (!this.isBlank(row.agencyUserDeptIds)) {
        this.form.agencyDeptIds = row.agencyUserDeptIds
      }
      if (!this.isBlank(row.agencyUserRoleIds)) {
        this.form.agencyRoleIds = row.agencyUserRoleIds
      }

      await this.setUserExtendFormJson(false);
      await this.setUserExtendFormData(row);
    },
    // 批量上传
    onHandleImportClick () {
      this.upload.title = '用户导入'
      this.upload.open = true
      this.upload.isUploading = false
      this.upload.file = null
    },
    handleSelectionChange (selection) {
      this.selectedUserIds = selection.map(item => item.agencyUserId);
    },
    // 批量导出
    onHandleExportClick () {
      let param = {
        agencyUserIds: this.selectedUserIds,
        type: this.selectedUserIds.length > 0 ? 1 : 2
      }
      downloadUserExcel(param)
        .then((data) => {
          if (!data) {
            return false
          }
          const blob = new Blob([data])
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.download = `导出用户数据.xlsx`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        },
          (error) => {
            this.$message.error('下载异常，请稍后再试')
          }
        )
    },
    onHandleDeleteBatch () {
      const delUsers = (userIds) => {
        batchDeleteAgencyUser(userIds)
          .then(() => {
            this.msgSuccess('用户删除成功')
            this.getAgencyUserList()
          })
          .catch(() => {
            this.msgError('用户删除失败')
          })
      }
      this.$confirm('确实要删除指定用户吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delUsers(this.selectedUserIds)
      })
    },
    handleFileUploadProgress () { },
    handleFileSuccess () { },
    handleFileRequest ({ file }) {
      this.upload.file = file
    },
    onConfirmUploadClick () {
      if (this.isBlank(this.upload.file)) {
        this.$message({
          message: '请上传文件',
          type: 'warning'
        })
        return
      }
      // const loading = ELEMENT.Loading.service({
      //   fullscreen: true,
      //   lock: true,
      //   text: this.progressText,
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(255, 255, 255, 0.5)'
      // })
      this.loadingVisible = true;
      var handler = null;
      agencyUserExcelImport(this.upload.file)
        .then((response) => {
          if (response.code == 200) {
            this.loadingVisible = false;
            if (response.msg == '部分导入成功') {
              this.msgSuccess('部分数据导入成功,正在下载导入异常的数据');
              window.open(response.data)
            } else {
              this.msgSuccess('导入成功')
            }
            this.upload = {
              open: false,
              title: '',
              isUploading: false,
              file: null
            }
            this.$refs.upload.clearFiles() // 上传完成后执行清空
            this.getAgencyUserList()
          } else {
            this.loadingVisible = false;
          }
          if (handler) {
            clearInterval(handler)
            handler = null;
          }
        })
        .catch((ex) => {
          this.loadingVisible = false;
          if (handler) {
            clearInterval(handler)
            handler = null;
          }
        })
      handler = agencyUserExcelImportProgress((data) => {
        this.progressText = data.progressName;

        console.log('progressdata', data)
      })
    },
    onCancelUploadClick () {
      this.upload = {
        open: false,
        title: '',
        isUploading: false,
        file: null
      }
      this.$refs.upload.clearFiles()
    },
    onCloseUploadDialogClick () {
      this.upload = {
        open: false,
        title: '',
        isUploading: false,
        file: null
      }
      this.$refs.upload.clearFiles()
    },
    deptFullName (deptAgencyId) {
      let depts = [];
      const findDept = (deptId) => {
        let dept = this.deptList.find(x => x.agencyDeptId == deptId);
        if (dept) {
          depts.unshift(dept.deptName);
          if (dept.parentId !== 0) {
            findDept(dept.parentId);
          }
        }
      }
      findDept(deptAgencyId);
      return depts.toString().replace(/,/g, '-');
    },
    async getUserExtendField () {
      let userInfoRes = await this.$store.dispatch("GetUserInfo")
      let userInfo = userInfoRes.data;

      if (userInfo && userInfo.adminAgencyId) {
        let agencyRes = await getAgency(userInfo.adminAgencyId)
        let extendField = agencyRes.data.extendField;

        this.userExtendProfile = extendField;

        this.showTable = true;
      }
    },
    setUserExtendFormJson (editable) {
      let formConfig = deepClone(userExtendFieldFormJson)
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          let formJson = {
            widgetList: this.userExtendProfile || [],
            formConfig: formConfig
          }

          this.$refs.vformRender.resetForm();
          this.$nextTick(() => {
            this.$refs.vformRender.setFormJson(formJson);
            this.$nextTick(() => {
              if (editable) {
                this.$refs.vformRender.enableForm(true);
              }
              else {
                this.$refs.vformRender.disableForm();
              }
              resolve();
            })

          })
        })
      })
    },
    setUserExtendFormData (row, editable) {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          let userData = {}
          if (row.extendFieldMap)
            Object.keys(row.extendFieldMap).forEach(key => {
              let widget = this.userExtendProfile.find(x => x.options.label === key)
              if (widget) {
                userData[widget.options.name] = row.extendFieldMap[key];
              }
            })
          this.$refs.vformRender.setFormData(userData)
          resolve();

        })
      })
    },
    async downloadTemplate () {
      let userInfoRes = await this.$store.dispatch("GetUserInfo")
      let userInfo = userInfoRes.data;

      if (userInfo && userInfo.adminAgencyId) {

        downloadImportTemplate(userInfo.adminAgencyId)
          .then(
            (data) => {
              if (!data) {
                return false
              }
              const blob = new Blob([data])
              const url = URL.createObjectURL(blob)
              const link = document.createElement('a')
              link.href = url
              link.download = `机构用户导入模板.xlsx`
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            },
            (error) => {
              this.$message.error('下载异常，请稍后再试')
            }
          )

      }
    },
    onCloseBindingDialogClick () {
      this.openUserBindAdd = false;
      this.$refs.bindUserForm.resetFields();
    },
    onCloseUserMobileChangeClick () {
      this.openUserMobileChange = false;
      this.$refs.bindUserMobile.resetFields();
    },
    showAddAgencyUserDialog (row) {
      // 暂存编辑的row
      this.bindUserForm = {
        agencyId: row.agencyId,
        number: row.number,
        name: row.name
      }
      this.openUserBindAdd = true;

    },
    changeAgencyUserMobile (row) {
      this.bindUserMobile.agencyUserId = row.agencyUserId
      this.openUserMobileChange = true;
    },
    saveBindingUserDialog () {
      this.$refs.bindUserForm.validate((valid) => {
        if (valid) {
          // 表单验证通过，绑定用户
          agencyUserBinding(this.bindUserForm)
            .then((response) => {
              if (response.code == 200) {
                this.msgSuccess('绑定成功')
                this.openUserBindAdd = false;
                this.getAgencyUserList()
              } else {
                this.msgError(response.msg)
              }
            })
        } else {
          // 表单验证失败
        }
      });
    },
    saveUserMobileChangeDialog () {
      this.$refs.bindUserMobile.validate((valid) => {
        if (valid) {
          // 表单验证通过，绑定用户
          agencyUserChangeBinding(this.bindUserMobile)
            .then((response) => {
              if (response.code == 200) {
                this.msgSuccess('更改成功')
                this.getAgencyUserList()
              } else {
                this.msgError(response.msg)
              }
            })
        } else {
          // 表单验证失败
        }
      });
    },
    unBindAgencyUser (row) {
      // 弹出提示：是否要解除用户绑定
      this.$confirm('确实要解除用户绑定吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        agencyUserUnBinding(row.agencyUserId)
          .then((response) => {
            if (response.code == 200) {
              this.msgSuccess('解绑成功')
              this.getAgencyUserList()
            } else {
              this.msgError(response.msg)
            }
          })
      })
    }
  }
}
</script>
<style scope="scoped" lang="scss">
.app-user {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;

  .el-form-item {
    margin-bottom: 0px;
  }
}
.vue-treeselect__control {
  height: 28px;
  border-radius: 4px;
}
.vue-treeselect {
  line-height: 26px;
  margin-top: 5px;
  font-size: 12px;
}
.vue-treeselect__placeholder,
.vue-treeselect__single-value {
  line-height: 26px;
}
// .el-table .cell {
//   height: auto !important;
// }
.user-extend-fields {
  .el-form-item__label::before {
    position: absolute;
    left: -9px;
  }
}

.no-right-border {
  border-right: none 0px !important;
}
.el-upload,
.el-upload-dragger {
  width: 100% !important;
}
</style>
