var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "clearfix app-container" }, [
      _c("div", { staticClass: "agency-left fl left-menu" }, [
        _c("ul", { staticClass: "tab-title" }, [
          _vm.userInfo.type == "2"
            ? _c(
                "li",
                {
                  class: { active: _vm.cur == 1 },
                  on: {
                    click: function ($event) {
                      return _vm.onTabClick(1)
                    },
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "tab-title-icon",
                    attrs: { "icon-class": "myInfo" },
                  }),
                  _vm._v(" 机构信息 "),
                ],
                1
              )
            : _vm._e(),
          _vm.userInfo.type == "2"
            ? _c(
                "li",
                {
                  class: { active: _vm.cur == 2 },
                  on: {
                    click: function ($event) {
                      return _vm.onTabClick(2)
                    },
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "tab-title-icon",
                    attrs: { "icon-class": "myVersion" },
                  }),
                  _vm._v(" 版本信息 "),
                ],
                1
              )
            : _vm._e(),
          _vm.userInfo.type == "2"
            ? _c(
                "li",
                {
                  class: { active: _vm.cur == 8 },
                  on: {
                    click: function ($event) {
                      return _vm.onTabClick(8)
                    },
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "tab-title-icon",
                    attrs: { "icon-class": "mySubAd" },
                  }),
                  _vm._v(" 子管理员 "),
                ],
                1
              )
            : _vm._e(),
          _vm.userInfo.type == "2" || _vm.agencyUser.type == "3"
            ? _c(
                "li",
                {
                  class: { active: _vm.cur == 3 },
                  on: {
                    click: function ($event) {
                      return _vm.onTabClick(3)
                    },
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "tab-title-icon",
                    attrs: { "icon-class": "myAgency" },
                  }),
                  _vm._v(" 组织架构 "),
                ],
                1
              )
            : _vm._e(),
          _vm.userInfo.type == "2" || _vm.agencyUser.type == "3"
            ? _c(
                "li",
                {
                  class: { active: _vm.cur == 4 },
                  on: {
                    click: function ($event) {
                      return _vm.onTabClick(4)
                    },
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "tab-title-icon",
                    attrs: { "icon-class": "myRole" },
                  }),
                  _vm._v(" 角色管理 "),
                ],
                1
              )
            : _vm._e(),
          _vm.userInfo.type == "2" || _vm.agencyUser.type == "3"
            ? _c(
                "li",
                {
                  class: { active: _vm.cur == 5 },
                  on: {
                    click: function ($event) {
                      return _vm.onTabClick(5)
                    },
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "tab-title-icon",
                    attrs: { "icon-class": "myUser" },
                  }),
                  _vm._v(" 用户管理 "),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticStyle: { width: "20px", "flex-shrink": "0" } }),
      _c("div", { staticClass: "agency-right fr" }, [
        _vm.cur == 1
          ? _c("div", { staticClass: "tab-content" }, [
              _c("div", { staticClass: "fx-corp-info-panel" }, [
                _c("div", { staticClass: "agency-panel-title" }, [
                  _vm._v("机构名称"),
                ]),
                _c("div", { staticClass: "fx-row-info-panel" }, [
                  _c("div", { staticClass: "label" }, [
                    _c("img", {
                      staticClass: "agencyLogo",
                      attrs: { src: _vm.agency.logo },
                    }),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("span", { staticClass: "content-row" }, [
                      _vm._v(_vm._s(_vm.agency.name)),
                    ]),
                  ]),
                ]),
              ]),
              _vm.dashboardInfo
                ? _c("div", { staticClass: "fx-agency-info-panel" }, [
                    _c("div", { staticClass: "agency-panel-title" }, [
                      _vm._v("机构概览"),
                    ]),
                    _c("div", { staticClass: "agency-chart-container" }, [
                      _c("div", { staticClass: "agency-chart-item" }, [
                        _c("span", { staticClass: "current-amount" }, [
                          _vm._v(_vm._s(_vm.dashboardInfo.projectNum)),
                        ]),
                        _c("span", { staticClass: "sum" }, [
                          _vm._v("应用数量"),
                        ]),
                      ]),
                      _c("div", { staticClass: "agency-chart-item" }, [
                        _c("span", { staticClass: "current-amount" }, [
                          _vm._v(_vm._s(_vm.dashboardInfo.projectFormNum)),
                        ]),
                        _c("span", { staticClass: "sum" }, [
                          _vm._v("应用内表单数量"),
                        ]),
                      ]),
                      _c("div", { staticClass: "agency-chart-item" }, [
                        _c("span", { staticClass: "current-amount" }, [
                          _vm._v(_vm._s(_vm.dashboardInfo.formNum)),
                        ]),
                        _c("span", { staticClass: "sum" }, [
                          _vm._v("独立表单数量"),
                        ]),
                      ]),
                      _c("div", { staticClass: "agency-chart-item" }, [
                        _c("span", { staticClass: "current-amount" }, [
                          _vm._v(_vm._s(_vm.dashboardInfo.totalFillNum)),
                        ]),
                        _c("span", { staticClass: "sum" }, [
                          _vm._v("已提交数据总量"),
                        ]),
                      ]),
                      _c("div", { staticClass: "agency-chart-item" }, [
                        _c("span", { staticClass: "current-amount" }, [
                          _vm._v(_vm._s(_vm.dashboardInfo.currentMonthFillNum)),
                        ]),
                        _c("span", { staticClass: "sum" }, [
                          _vm._v("当月提交数据总量"),
                        ]),
                      ]),
                      _c("div", { staticClass: "agency-chart-item" }, [
                        _c("span", { staticClass: "current-amount" }, [
                          _vm._v(_vm._s(_vm.dashboardInfo.todayFillNum)),
                        ]),
                        _c("span", { staticClass: "sum" }, [
                          _vm._v("当日提交数据总量"),
                        ]),
                      ]),
                      _c("div", { staticClass: "agency-chart-item" }, [
                        _c("span", { staticClass: "current-amount" }, [
                          _vm._v(_vm._s(_vm.dashboardInfo.totalFileNum)),
                        ]),
                        _c("span", { staticClass: "sum" }, [
                          _vm._v("附件总数量"),
                        ]),
                      ]),
                      _c("div", { staticClass: "agency-chart-item" }, [
                        _c("span", { staticClass: "current-amount" }, [
                          _vm._v(
                            _vm._s(_vm.dashboardInfo.totalFileSize) + " MB"
                          ),
                        ]),
                        _c("span", { staticClass: "sum" }, [
                          _vm._v("附件上传总量"),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.cur == 2
          ? _c("div", { staticClass: "tab-content" }, [
              _c("div", { staticClass: "fx-version-info-panel" }, [
                _vm._m(0),
                _c(
                  "div",
                  {
                    staticClass: "privilege-wrapper",
                    staticStyle: { "margin-top": "16px" },
                  },
                  [
                    _c("div", { staticClass: "label" }, [_vm._v("系统容量")]),
                    _c(
                      "div",
                      { staticClass: "content", staticStyle: { margin: "0" } },
                      [
                        _c("div", { staticClass: "privilege-item" }, [
                          _c(
                            "div",
                            { staticClass: "name-wrapper" },
                            [
                              _c("svg-icon", {
                                staticStyle: {
                                  width: "22px",
                                  height: "22px",
                                  "vertical-align": "-5px",
                                },
                                attrs: { "icon-class": "privilegeUser" },
                              }),
                              _c("span", { staticClass: "name-text" }, [
                                _vm._v("用户量"),
                              ]),
                            ],
                            1
                          ),
                          _vm._m(1),
                          _c("div", { staticClass: "content-wrapper" }, [
                            _c("div", { staticClass: "content-left fl" }, [
                              _vm._v(
                                "已用：" +
                                  _vm._s(
                                    _vm.versionDashboardInfo.agencyUserNum
                                  ) +
                                  "人"
                              ),
                            ]),
                            _c("div", { staticClass: "content-right fr" }, [
                              _vm._v("总量：无限"),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.cur == 3
          ? _c(
              "div",
              { staticClass: "tab-content tab-content-bg" },
              [_c("Dept", { attrs: { agencyId: _vm.agency.agencyId } })],
              1
            )
          : _vm._e(),
        _vm.cur == 4
          ? _c(
              "div",
              { staticClass: "tab-content tab-content-bg" },
              [_c("Role", { attrs: { agencyId: _vm.agency.agencyId } })],
              1
            )
          : _vm._e(),
        _vm.cur == 5
          ? _c(
              "div",
              { staticClass: "tab-content tab-content-bg" },
              [_c("user", { attrs: { cur: _vm.cur } })],
              1
            )
          : _vm._e(),
        _vm.cur == 6
          ? _c(
              "div",
              { staticClass: "tab-content tab-content-bg" },
              [_c("student", { attrs: { cur: _vm.cur } })],
              1
            )
          : _vm._e(),
        _vm.cur == 7
          ? _c(
              "div",
              { staticClass: "tab-content tab-content-bg" },
              [_c("teacher", { attrs: { cur: _vm.cur } })],
              1
            )
          : _vm._e(),
        _vm.cur == 8
          ? _c(
              "div",
              { staticClass: "tab-content tab-content-bg" },
              [
                _c("subAd", {
                  attrs: { cur: _vm.cur, agencyId: _vm.agency.agencyId },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "version-wrapper" }, [
      _c("div", { staticClass: "label" }, [_vm._v("会员版本")]),
      _c("div", { staticClass: "content" }, [
        _c("span", { staticClass: "version-name" }, [_vm._v("机构免费版")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "progress-bar" }, [
      _c("div", { staticClass: "progress-wrapper" }, [
        _c("div", { staticClass: "value-bar" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }