var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-role" },
    [
      _c(
        "el-row",
        { staticStyle: { margin: "0" }, attrs: { gutter: 20 } },
        [
          _c(
            "div",
            {
              staticClass: "head-container fr",
              staticStyle: { "margin-bottom": "30px" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onAddRoleClick(1)
                    },
                  },
                },
                [_vm._v("新增角色组")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { padding: "0" }, attrs: { span: 24, xs: 24 } },
            [
              _c(
                "div",
                { staticClass: "head-container" },
                [
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      data: _vm.treeOptions,
                      props: _vm.treeProps,
                      "expand-on-click-node": false,
                      "filter-node-method": _vm.filterNode,
                      "default-expand-all": _vm.defaultExpandAll,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var node = ref.node
                          var data = ref.data
                          return _c(
                            "span",
                            {
                              staticClass: "custom-tree-node",
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.onRoleTreeMouseEnter(data)
                                },
                                mouseleave: function ($event) {
                                  return _vm.onRoleTreeMouseLeave(data)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "fl node-label" }, [
                                _vm._v(_vm._s(node.label)),
                              ]),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: data.showBtn,
                                      expression: "data.showBtn",
                                    },
                                  ],
                                  staticClass: "fr node-btn",
                                },
                                [
                                  data.parentId == 0
                                    ? _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.onAddRoleClick(data, 2)
                                            },
                                          },
                                        },
                                        [_vm._v("添加角色")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onEditRoleClick(data)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDeleteRoleClick(data)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              ),
                            ]
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.roleType == 1 ? "新建角色组" : "新建角色",
            visible: _vm.openBuild,
            width: "500px",
            "append-to-body": "",
            "before-close": _vm.onHandleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openBuild = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "roleGroup",
              attrs: {
                model: _vm.roleGroup,
                rules: _vm.roleGroupRules,
                "label-width": "110px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.roleType == 1 ? "角色组名称" : "角色名称",
                    prop: "roleName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        _vm.roleType == 1
                          ? "请输入角色组名称"
                          : "请输入角色名称",
                      spellcheck: "false",
                    },
                    model: {
                      value: _vm.roleGroup.roleName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.roleGroup,
                          "roleName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "roleGroup.roleName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onSubmitClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.OnCancelClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.roleType == 1 ? "修改角色组" : "修改角色",
            visible: _vm.openEdit,
            width: "500px",
            "append-to-body": "",
            "before-close": _vm.onHandleEditClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openEdit = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "roleGroup",
              attrs: {
                model: _vm.roleGroup,
                rules: _vm.roleGroupRules,
                "label-width": "110px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.roleType == 1 ? "角色组名称" : "角色名称",
                    prop: "roleName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        _vm.roleType == 1
                          ? "请输入角色组名称"
                          : "请输入角色名称",
                      spellcheck: "false",
                    },
                    model: {
                      value: _vm.roleGroup.roleName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.roleGroup,
                          "roleName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "roleGroup.roleName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onEditSubmitClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.OnEditCancelClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }