var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-user" },
    [
      _c(
        "el-row",
        {
          staticClass: "fr",
          staticStyle: { "text-align": "right", "margin-bottom": "20px" },
          attrs: { gutter: 10 },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.onHandleAdd },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "table",
          staticStyle: { width: "100%", cursor: "pointer" },
          attrs: { data: _vm.agencySubAdminList },
          on: {
            "cell-mouse-enter": _vm.onEnterTable,
            "cell-mouse-leave": _vm.onLeaveTable,
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "number", label: "编号", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "姓名", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "date", label: "绑定状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.bindStatus == "1" ? "未绑定" : "已绑定"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "", align: "center" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.showBtn
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "text",
                                icon: "el-icon-delete",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onHandleDelClick(scope.row)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加子管理员",
            visible: _vm.open,
            width: "650px",
            "custom-class": "add-sub-admin-dialog",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
            close: _vm.onAddAdClose,
          },
        },
        [
          _c("div", { staticClass: "fx_member_select" }, [
            _c(
              "ul",
              { staticClass: "select-list" },
              _vm._l(_vm.selectAdArr, function (item, index) {
                return _c(
                  "li",
                  { key: index, staticClass: "select-item" },
                  [
                    item.type == "member"
                      ? [
                          _c("svg-icon", {
                            staticStyle: {
                              width: "20px",
                              height: "20px",
                              "vertical-align": "-4px",
                              "margin-right": "5px",
                            },
                            attrs: { "icon-class": "memberIcon" },
                          }),
                        ]
                      : _vm._e(),
                    item.type == "dept"
                      ? [
                          _c("svg-icon", {
                            staticStyle: {
                              width: "20px",
                              height: "20px",
                              "vertical-align": "-4px",
                              "margin-right": "5px",
                            },
                            attrs: { "icon-class": "deptIcon" },
                          }),
                        ]
                      : _vm._e(),
                    item.type == "role"
                      ? [
                          _c("svg-icon", {
                            staticStyle: {
                              width: "20px",
                              height: "20px",
                              "vertical-align": "-4px",
                              "margin-right": "5px",
                            },
                            attrs: { "icon-class": "roleIcon" },
                          }),
                        ]
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(item.name))]),
                    _c(
                      "span",
                      {
                        staticClass: "remove-btn",
                        on: {
                          click: function ($event) {
                            return _vm.onRemoveSelect(item, index)
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          staticStyle: {
                            width: "12px",
                            height: "12px",
                            "vertical-align": "-1px",
                          },
                          attrs: { "icon-class": "del" },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                )
              }),
              0
            ),
            _c("div", { staticClass: "select-menu" }, [
              _c("div", { staticClass: "select-btn select" }, [_vm._v("成员")]),
              _c(
                "div",
                {
                  staticClass: "select-search-pane",
                  style: { width: _vm.isFocus ? "100%" : "114px" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.memberParams.searchTxt,
                        expression: "memberParams.searchTxt",
                      },
                    ],
                    attrs: { type: "text", placeholder: "搜索" },
                    domProps: { value: _vm.memberParams.searchTxt },
                    on: {
                      change: _vm.onChangeSearchTxt,
                      focus: _vm.onFocusSearchTxt,
                      blur: _vm.onBlurSearchTxt,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.memberParams,
                          "searchTxt",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm.memberParams.searchTxt != ""
                    ? _c("svg-icon", {
                        staticStyle: {
                          width: "12px",
                          height: "12px",
                          position: "absolute",
                          right: "20px",
                          top: "12px",
                          cursor: "pointer",
                        },
                        attrs: { "icon-class": "del" },
                        on: { click: _vm.onClearSearchTxt },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "select-pane" }, [
              _c("div", { staticClass: "member-pane" }, [
                _c("div", { staticClass: "select-department fl" }, [
                  _c(
                    "div",
                    {
                      staticClass: "depart-root",
                      on: { click: _vm.onMemberClick },
                    },
                    [_vm._v("全部成员")]
                  ),
                  _c(
                    "div",
                    { staticClass: "x-department-tree" },
                    [
                      _c("el-tree", {
                        attrs: {
                          data: _vm.deptOptions,
                          props: _vm.deptTreeProps,
                        },
                        on: { "node-click": _vm.onHandleNodeClick },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm.memberList && _vm.memberList.length > 0
                  ? _c(
                      "div",
                      { staticClass: "member-wrapper fr" },
                      [
                        _c("el-alert", {
                          attrs: {
                            type: "warning",
                            title: "只能选择已绑定账号的用户",
                            closable: false,
                          },
                        }),
                        _c("div", { staticClass: "select-all-item" }, [
                          _c("div", { staticClass: "count-label" }, [
                            _vm._v("已选 "),
                            _c("span", { staticClass: "check-count" }, [
                              _vm._v("0"),
                            ]),
                            _vm._v("/" + _vm._s(_vm.memberList.length)),
                          ]),
                          _c("div", { staticClass: "check-all x-check" }, [
                            _c("i", { staticClass: "icon-blank" }),
                          ]),
                        ]),
                        _c("div", { staticClass: "select-member" }, [
                          _c(
                            "ul",
                            [
                              _c(
                                "li",
                                { staticStyle: { background: "#ebf8f7" } },
                                [
                                  _c("span", { staticClass: "user-name fl" }, [
                                    _vm._v("姓名"),
                                  ]),
                                  _c("span", { staticClass: "user-name fl" }, [
                                    _vm._v("编号"),
                                  ]),
                                  _c("span", { staticClass: "user-name fl" }, [
                                    _vm._v("部门"),
                                  ]),
                                  _c("div", { staticClass: "select-check fr" }),
                                ]
                              ),
                              _vm._l(_vm.memberList, function (item, index) {
                                return _c(
                                  "li",
                                  {
                                    key: item.userId,
                                    on: {
                                      click: function ($event) {
                                        return _vm.onCheckBoxUser(index)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "user-name fl" },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "user-name fl" },
                                      [_vm._v(_vm._s(item.number))]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "user-name fl" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            (
                                              item.agencyUserDeptNames || []
                                            ).join(",")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "select-check fr",
                                        class: { select: item.checked },
                                      },
                                      [_c("i", { staticClass: "icon-blank" })]
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "member-wrapper fr" }, [
                      _c("span", { staticClass: "empty-tips" }, [
                        _vm._v("没有可选成员"),
                        _c("br"),
                        _vm._v("(只能选择已绑定账号的用户)"),
                      ]),
                    ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onAddAdConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }