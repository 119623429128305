var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-dept" },
    [
      _c(
        "el-row",
        { staticStyle: { margin: "0" }, attrs: { gutter: 20 } },
        [
          _c(
            "div",
            {
              staticClass: "head-container fr",
              staticStyle: { "margin-bottom": "10px" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.onAddAllDeptClick },
                },
                [_vm._v("添加")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    icon: "el-icon-upload",
                    size: "mini",
                  },
                  on: { click: _vm.onHandleImportClick },
                },
                [_vm._v("批量导入")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { padding: "0" }, attrs: { span: 24, xs: 24 } },
            [
              _c(
                "div",
                { staticClass: "head-container" },
                [
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      data: _vm.treeOptions,
                      props: _vm.treeProps,
                      "expand-on-click-node": false,
                      "filter-node-method": _vm.filterNode,
                      "default-expand-all": _vm.defaultExpandAll,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var node = ref.node
                          var data = ref.data
                          return _c(
                            "span",
                            {
                              staticClass: "custom-tree-node",
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.onRoleTreeMouseEnter(data)
                                },
                                mouseleave: function ($event) {
                                  return _vm.onRoleTreeMouseLeave(data)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "fl node-label" }, [
                                _vm._v(_vm._s(node.label)),
                              ]),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: data.showTag,
                                      expression: "data.showTag",
                                    },
                                  ],
                                  staticClass: "dept-manager-tag",
                                },
                                [_vm._v("负责人")]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: data.showTag,
                                      expression: "data.showTag",
                                    },
                                  ],
                                  staticClass: "dept-manager-name",
                                },
                                [_vm._v(_vm._s(data.leaderName))]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: data.showBtn,
                                      expression: "data.showBtn",
                                    },
                                  ],
                                  staticClass: "fr node-btn",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDeptLeaderClick(data)
                                        },
                                      },
                                    },
                                    [_vm._v("设置部门负责人")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDeptLeaderTestClick(data)
                                        },
                                      },
                                    },
                                    [_vm._v("设置部门负责人2")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAddDeptClick(data)
                                        },
                                      },
                                    },
                                    [_vm._v("新增")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onEditDeptClick(data)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { color: "#F80F0F" },
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDelDeptClick(data)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              ),
                            ]
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("UserSelect", { ref: "userSelect" }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增组织架构",
            visible: _vm.open,
            width: "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
            close: _vm.onHandleAgencyDeptClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.agencyDept,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "组织名称", prop: "deptName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入组织名称" },
                            model: {
                              value: _vm.agencyDept.deptName,
                              callback: function ($$v) {
                                _vm.$set(_vm.agencyDept, "deptName", $$v)
                              },
                              expression: "agencyDept.deptName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "显示排序", prop: "sort" } },
                        [
                          _c("el-input-number", {
                            attrs: { "controls-position": "right", min: 0 },
                            model: {
                              value: _vm.agencyDept.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.agencyDept, "sort", $$v)
                              },
                              expression: "agencyDept.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onSubmitDeptClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.oncancelDeptClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.userSelect.title,
            visible: _vm.userSelect.userOpen,
            width: "1200px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.userSelect, "userOpen", $event)
            },
            close: _vm.onCloseDialogClick,
          },
        },
        [
          _c("div", { staticClass: "userSelect-container" }, [
            _c(
              "div",
              { staticClass: "userSelect-group" },
              [
                _vm.selectAgencyUserList.length > 0
                  ? [
                      _c("h2", { staticClass: "userSelect-title" }, [
                        _vm._v(
                          "已选择: " +
                            _vm._s(_vm.selectAgencyUserList.length) +
                            "人"
                        ),
                      ]),
                      _vm._l(_vm.selectAgencyUserList, function (item, index) {
                        return _c(
                          "div",
                          {
                            staticClass: "selected-item",
                            on: {
                              click: function ($event) {
                                return _vm.onDelSelectUser(index)
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(item))]),
                            _c("svg-icon", {
                              staticStyle: {
                                "margin-left": "5px",
                                float: "right",
                                "margin-top": "12px",
                                "font-size": "14px",
                              },
                              attrs: { "icon-class": "del" },
                            }),
                          ],
                          1
                        )
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c("div", { staticClass: "userSelect-right" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.userSelect.cur == 2,
                      expression: "userSelect.cur == 2",
                    },
                  ],
                },
                [
                  _c(
                    "el-form",
                    { ref: "userSelectForm", attrs: { inline: true } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "归属部门" } },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      options: _vm.agencyDeptOptions,
                                      multiple: false,
                                      flat: false,
                                      "show-count": true,
                                      "default-expand-level": 1,
                                      placeholder: "请选择归属部门",
                                    },
                                    model: {
                                      value: _vm.queryParams.agencyDeptId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "agencyDeptId",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.agencyDeptId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "所属角色" } },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      options: _vm.agencyRoleOptions,
                                      multiple: false,
                                      flat: false,
                                      "show-count": true,
                                      "default-expand-level": 1,
                                      "disable-branch-nodes": true,
                                      placeholder: "请选择所属角色",
                                    },
                                    model: {
                                      value: _vm.queryParams.agencyRoleId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "agencyRoleId",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.agencyRoleId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "编号" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入编号",
                                      clearable: "",
                                      size: "small",
                                    },
                                    model: {
                                      value: _vm.queryParams.number,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.queryParams, "number", $$v)
                                      },
                                      expression: "queryParams.number",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "名称" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入用户名称",
                                      clearable: "",
                                      size: "small",
                                    },
                                    model: {
                                      value: _vm.queryParams.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.queryParams, "name", $$v)
                                      },
                                      expression: "queryParams.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                size: "medium",
                              },
                              on: { click: _vm.onSearchClick },
                            },
                            [_vm._v("搜索")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-refresh",
                                size: "medium",
                              },
                              on: { click: _vm.onResetClick },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.agencyUserList },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "number",
                          label: "编号",
                          "min-width": "8%",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: "姓名",
                          "min-width": "8%",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          "min-width": "12%",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "text",
                                      icon: "el-icon-select",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onHandleCheckClick(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("选择")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.queryParams.total > 0,
                        expression: "queryParams.total>0",
                      },
                    ],
                    attrs: {
                      total: _vm.queryParams.total,
                      page: _vm.queryParams.pageNum,
                      limit: _vm.queryParams.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.queryParams, "pageNum", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.queryParams, "pageSize", $event)
                      },
                      pagination: _vm.getAgencyUserList,
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { float: "right", "margin-top": "60px" },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onConfirmAddLeaderClick },
                        },
                        [_vm._v("确 定")]
                      ),
                      _c(
                        "el-button",
                        { on: { click: _vm.onCancelLeaderClick } },
                        [_vm._v("取 消")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.upload.title,
            visible: _vm.upload.open,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.upload, "open", $event)
            },
            close: _vm.onCloseUploadDialogClick,
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              attrs: {
                limit: 1,
                accept: ".xlsx, .xls",
                headers: _vm.upload.headers,
                action: "",
                disabled: _vm.upload.isUploading,
                "on-progress": _vm.handleFileUploadProgress,
                "on-success": _vm.handleFileSuccess,
                "http-request": _vm.handleFileRequest,
                drag: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  staticStyle: { "margin-top": "5px", "line-height": "16px" },
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "font-size": "12px" },
                      attrs: { type: "text" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/fillFile/124/1742176534061383680/6856/fileupload57705/%E6%9C%BA%E6%9E%84%E9%83%A8%E9%97%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx",
                            download: "机构部门导入模板.xlsx",
                          },
                        },
                        [_vm._v("下载模板")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      staticStyle: {
                        color: "red",
                        display: "inline-block",
                        "margin-top": "0",
                        "margin-left": "5px",
                      },
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("提示：仅允许导入“xls”或“xlsx”格式文件！")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmUploadClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelUploadClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "成员列表",
            visible: _vm.deptLeader.open,
            width: "610px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.deptLeader, "open", $event)
            },
            close: _vm.onLeaderClose,
          },
        },
        [
          _c("div", { staticClass: "fx_member_select" }, [
            _c("div", { staticClass: "select-menu" }, [
              _c("div", { staticClass: "select-btn select" }, [_vm._v("成员")]),
              _c(
                "div",
                {
                  staticClass: "select-search-pane",
                  style: { width: _vm.isFocus ? "100%" : "114px" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.memberParams.searchTxt,
                        expression: "memberParams.searchTxt",
                      },
                    ],
                    attrs: { type: "text", placeholder: "搜索" },
                    domProps: { value: _vm.memberParams.searchTxt },
                    on: {
                      change: _vm.onChangeSearchTxt,
                      focus: _vm.onFocusSearchTxt,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.memberParams,
                          "searchTxt",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm.memberParams.searchTxt != ""
                    ? _c("svg-icon", {
                        staticStyle: {
                          width: "12px",
                          height: "12px",
                          position: "absolute",
                          right: "20px",
                          top: "12px",
                          cursor: "pointer",
                        },
                        attrs: { "icon-class": "del" },
                        on: { click: _vm.onClearSearchTxt },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "select-pane" }, [
              _c("div", { staticClass: "member-pane" }, [
                _vm.memberList && _vm.memberList.length > 0
                  ? _c("div", { staticClass: "member-wrapper" }, [
                      _c("div", { staticClass: "select-member" }, [
                        _c(
                          "ul",
                          [
                            _c(
                              "li",
                              { staticStyle: { background: "#ebf8f7" } },
                              [
                                _c("span", { staticClass: "user-name fl" }, [
                                  _vm._v("姓名"),
                                ]),
                                _c("span", { staticClass: "user-name fl" }, [
                                  _vm._v("编号"),
                                ]),
                                _c("span", { staticClass: "user-name fl" }, [
                                  _vm._v("部门"),
                                ]),
                                _c("div", { staticClass: "select-check fr" }),
                              ]
                            ),
                            _vm._l(_vm.memberList, function (item, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  on: {
                                    click: function ($event) {
                                      return _vm.onRadioLeader(
                                        item.agencyUserId
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "user-name fl" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c("span", { staticClass: "user-name fl" }, [
                                    _vm._v(_vm._s(item.number)),
                                  ]),
                                  _c("span", { staticClass: "user-name fl" }, [
                                    _vm._v(
                                      _vm._s(
                                        (item.agencyUserDeptNames || []).join(
                                          ","
                                        )
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "select-check fr",
                                      class: {
                                        select: _vm.radioIndex.find(function (
                                          x
                                        ) {
                                          return x == item.agencyUserId
                                        }),
                                      },
                                    },
                                    [_c("i", { staticClass: "icon-blank" })]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                    ])
                  : _c("div", { staticClass: "member-wrapper" }, [
                      _c("span", { staticClass: "empty-tips" }, [
                        _vm._v("没有可选成员"),
                      ]),
                    ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onLeaderCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onLeaderConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }