<template>
  <div class="app-dept">

    <el-row :gutter="20" style="margin: 0">

      <div class="head-container fr" style="margin-bottom: 10px;">
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="onAddAllDeptClick">添加</el-button>
        <el-button type="warning" icon="el-icon-upload" size="mini" @click="onHandleImportClick">批量导入</el-button>
      </div>

      <el-col :span="24" :xs="24" style="padding: 0">
        <div class="head-container">
          <el-tree :data="treeOptions" :props="treeProps" :expand-on-click-node="false" :filter-node-method="filterNode" ref="tree" :default-expand-all="defaultExpandAll">
            <span class="custom-tree-node" slot-scope="{ node, data }" @mouseenter="onRoleTreeMouseEnter(data)" @mouseleave="onRoleTreeMouseLeave(data)">
              <span class="fl node-label">{{ node.label }}</span>
              <span class="dept-manager-tag" v-show="data.showTag">负责人</span>
              <span class="dept-manager-name" v-show="data.showTag">{{data.leaderName}}</span>
              <span class="fr node-btn" v-show="data.showBtn">
                <span @click="onDeptLeaderClick(data)">设置部门负责人</span>
                <span @click="onDeptLeaderTestClick(data)">设置部门负责人2</span>
                <span @click="onAddDeptClick(data)">新增</span>
                <span type="text" size="mini" @click="onEditDeptClick(data)">修改</span>
                <span type="text" size="mini" @click="onDelDeptClick(data)" style="color: #F80F0F">删除</span>
              </span>
            </span>
          </el-tree>
        </div>
      </el-col>

<!--      <el-col :span="19" :xs="24">-->
<!--        <el-table :data="agencyDeptList">-->
<!--          <el-table-column label="等级" align="center" prop="deptName"></el-table-column>-->
<!--          <el-table-column label="负责人" align="center" prop="leaderList">-->
<!--            <template slot-scope="scope">-->
<!--              <div v-if="scope.row.leaders">-->
<!--                <template v-for="(item,index) in scope.row.leaders" style="display: inline-block">-->
<!--                  <span>{{item}}</span>-->
<!--                  <span v-if="index < scope.row.leaders.length - 1">、</span>-->
<!--                </template>-->
<!--              </div>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column label="操作" align="center" width="160" class-name="small-padding fixed-width">-->
<!--            <template slot-scope="scope">-->
<!--              <el-button size="mini" type="text" icon="el-icon-edit" @click="onSelectUserClick(scope.row)">选择人员</el-button>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--        </el-table>-->
<!--      </el-col>-->

    </el-row>
    <UserSelect ref="userSelect"></UserSelect>

    <!-- 添加或修改部门对话框 -->
    <el-dialog title="新增组织架构" :visible.sync="open" width="600px" append-to-body @close="onHandleAgencyDeptClose">
      <el-form ref="form" :model="agencyDept" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="组织名称" prop="deptName">
              <el-input v-model="agencyDept.deptName" placeholder="请输入组织名称" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="显示排序" prop="sort">
              <el-input-number v-model="agencyDept.sort" controls-position="right" :min="0" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitDeptClick">确 定</el-button>
        <el-button @click="oncancelDeptClick">取 消</el-button>
      </div>
    </el-dialog>

    <!--选择人员对话框-->
    <el-dialog :title="userSelect.title" :visible.sync="userSelect.userOpen" width="1200px" append-to-body @close="onCloseDialogClick">
<!--      <ul class="user-tab">-->
<!--        <li @click="onUserTab(1)" :class="{active: userSelect.cur == 1}">小程序码邀请</li>-->
<!--        <li @click="onUserTab(2)" :class="{active: userSelect.cur == 2}">系统内部人员选择</li>-->
<!--      </ul>-->
      <div class="userSelect-container">
        <div class="userSelect-group">
          <template v-if="selectAgencyUserList.length > 0">
            <h2 class="userSelect-title">已选择: {{selectAgencyUserList.length}}人</h2>
            <div v-for="(item, index) in selectAgencyUserList" class="selected-item" @click="onDelSelectUser(index)">
              <span>{{item}}</span>
              <svg-icon icon-class="del" style="margin-left: 5px; float: right; margin-top: 12px; font-size: 14px;"/>
            </div>
          </template>
        </div>
        <!--        <div v-show="userSelect.cur == 1">二维码</div>-->
        <div class="userSelect-right">
          <div v-show="userSelect.cur == 2">

            <el-form :inline="true" ref="userSelectForm">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="归属部门">
                    <treeselect v-model="queryParams.agencyDeptId" :options="agencyDeptOptions" :multiple="false" :flat="false" :show-count="true" :default-expand-level="1" placeholder="请选择归属部门"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="所属角色">
                    <treeselect v-model="queryParams.agencyRoleId" :options="agencyRoleOptions" :multiple="false" :flat="false" :show-count="true" :default-expand-level="1" :disable-branch-nodes="true" placeholder="请选择所属角色"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="编号">
                    <el-input v-model="queryParams.number" placeholder="请输入编号" clearable size="small"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="名称">
                    <el-input v-model="queryParams.name" placeholder="请输入用户名称" clearable size="small"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item>
                <el-button class="filter-item" type="primary" icon="el-icon-search" size="medium" @click="onSearchClick">搜索</el-button>
                <el-button icon="el-icon-refresh" size="medium" @click="onResetClick">重置</el-button>
                <!--              <el-button class="filter-item" type="primary" icon="el-icon-plus" size="medium" @click="onAllAddClick" style="margin-right: 10px;">一键添加</el-button>-->
              </el-form-item>
            </el-form>

            <el-table :data="agencyUserList" style="width: 100%">
              <el-table-column prop="number" label="编号" min-width="8%" align="center"></el-table-column>
              <el-table-column prop="name" label="姓名" min-width="8%" align="center"></el-table-column>
              <el-table-column label="操作" min-width="12%" align="center">
                <template slot-scope="scope">
                  <el-button size="mini" type="text" icon="el-icon-select" @click="onHandleCheckClick(scope.row)">选择</el-button>
                </template>
              </el-table-column>
            </el-table>

            <pagination v-show="queryParams.total>0" :total="queryParams.total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getAgencyUserList"/>

            <div slot="footer" class="dialog-footer" style="float: right; margin-top: 60px">
              <el-button type="primary" @click="onConfirmAddLeaderClick">确 定</el-button>
              <el-button @click="onCancelLeaderClick">取 消</el-button>
            </div>

          </div>
        </div>
      </div>
    </el-dialog>

    <!--批量导入组织架构-->
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="400px" append-to-body @close="onCloseUploadDialogClick">
      <el-upload ref="upload" :limit="1" accept=".xlsx, .xls" :headers="upload.headers" action=""
                 :disabled="upload.isUploading" :on-progress="handleFileUploadProgress" :on-success="handleFileSuccess" :http-request="handleFileRequest" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip" style="margin-top: 5px; line-height: 16px;">
          <el-button type="text" style="font-size: 12px;"><a href='https://smart-form-prod.oss-cn-beijing.aliyuncs.com/fillFile/124/1742176534061383680/6856/fileupload57705/%E6%9C%BA%E6%9E%84%E9%83%A8%E9%97%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx' download="机构部门导入模板.xlsx">下载模板</a></el-button>
<!--          <el-button type="text" style="font-size: 12px;"><a href='https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/%E6%9C%BA%E6%9E%84%E9%83%A8%E9%97%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx' download="机构部门导入模板.xlsx">下载模板</a></el-button>-->
<!--          <el-link type="info" style="font-size:12px" href='https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/%E6%9C%BA%E6%9E%84%E9%83%A8%E9%97%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx' download="机构部门导入模板.xlsx">下载模板</el-link>-->
          <div class="el-upload__tip" style="color:red; display: inline-block; margin-top: 0; margin-left: 5px;" slot="tip">提示：仅允许导入“xls”或“xlsx”格式文件！</div>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmUploadClick">确 定</el-button>
        <el-button @click="onCancelUploadClick">取 消</el-button>
      </div>
    </el-dialog>

    <!--3.0.0.1 设置部门负责人-->
    <el-dialog title="成员列表" :visible.sync="deptLeader.open" width="610px" append-to-body @close="onLeaderClose">
      <div class="fx_member_select">
        <div class="select-menu">
          <div class="select-btn select">成员</div>
          <div class="select-search-pane" :style="{'width':(isFocus ? '100%' : '114px')}">
            <input type="text" v-model="memberParams.searchTxt" placeholder="搜索" @change="onChangeSearchTxt" @focus="onFocusSearchTxt"></input>
            <svg-icon icon-class="del" style="width: 12px; height: 12px; position: absolute; right: 20px; top: 12px; cursor: pointer" @click="onClearSearchTxt" v-if="memberParams.searchTxt != ''"/>
          </div>
        </div>
        <div class="select-pane">
          <div class="member-pane">
            <div class="member-wrapper" v-if="memberList && memberList.length > 0">
              <div class="select-member">
                <ul>
                  <li style="background: #ebf8f7">
                    <span class="user-name fl">姓名</span>
                    <span class="user-name fl">编号</span>
                    <span class="user-name fl">部门</span>
                    <div class="select-check fr">
                    </div>
                  </li>
                  <li v-for="(item,index) in memberList" :key="index" @click="onRadioLeader(item.agencyUserId)">
                    <span class="user-name fl">{{item.name}}</span>
                    <span class="user-name fl">{{item.number}}</span>
                    <span class="user-name fl">{{(item.agencyUserDeptNames||[]).join(",")}}</span>
                    <div class="select-check fr" :class="{select: radioIndex.find(x=>x==item.agencyUserId)}">
                      <i class="icon-blank"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="member-wrapper" v-else>
              <span class="empty-tips">没有可选成员</span>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="margin-top: -20px;">
        <el-button @click="onLeaderCancel">取 消</el-button>
        <el-button type="primary" @click="onLeaderConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <!--3.0.0.1 设置部门负责人-->


  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import {updateAgencyDept, agencyDeptList, agencyDeptLevelList, delAgencyDept, agencyRoleList, agencyUserList, agencyLeader, agencyDeptExcelImport, agencyDeptLeader, agencyUserMemberList} from "@/api/system/agency";
import ELEMENT from "element-ui"
import UserSelect from "./components/user-select"
export default {
  name: "User",
  components: {
    Treeselect,
    UserSelect
  },
  props: {
    agencyId: {
      type: Number
    }
  },
  data() {
    return {
      treeProps: {
        children: "children",
        label: "deptName"
      },
      treeOptions: [],
      defaultExpandAll: false, //是否默认展开所有节点
      agencyDept: {
        agencyId: this.agencyId,
        deptName: '',
        leader: '',
        level: 1,
        parentId: 0,
        sort: 0,
      },
      open: false,
      title: "",
      dialogType: null, //区分是新增还是修改
      rules: {
        deptName: [
          { required: true, message: "组织名称不能为空", trigger: "blur" }
        ],
        sort: [
          { required: true, message: "显示排序不能为空", trigger: "blur" }
        ],
      },
      nodeData: {},
      //选择负责人相关
      agencyDeptList: [],
      userSelect: {
        userOpen: false,
        title: '',
        cur: 2,
        agencyDeptId: null
      },
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        bindStatus: '',
      },
      agencyDeptOptions: [],
      agencyRoleOptions: [],
      agencyUserList: [], //选择负责人弹框data
      selectAgencyUserList: [],
      leader: [],
      // 批量导入组织架构
      upload: {
        open: false,
        title: "",
        isUploading: false, // 是否禁用上传
        file: null
      },
      // 3.0.0.1 部门设置负责人
      deptLeader: {
        open: false
      },
      memberParams: {
        agencyId: null,
        searchTxt: ''
      },
      isFocus: false,
      memberList: [],
      radioIndex: [] // 新版，注意此处： NUmber 0 会被解析成null
    };
  },
  watch: {},
  created() {
    this.getTreeSelect()
  },
  computed: {},
  methods: {
    // 部门下拉树
    getTreeSelect() {
      agencyDeptList().then(response => {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].showBtn = false
          if (!this.isBlank(response.data[i].leaderId)) {
            response.data[i].showTag = true
          }
        }
        let treeList = response.data;
        this.treeOptions = this.handleTree(treeList, "agencyDeptId");
      });
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    //tree树形控件 鼠标滑过、离开
    onRoleTreeMouseEnter(data) {
      this.$set(data, 'showBtn', true)
    },
    onRoleTreeMouseLeave(data) {
      this.$set(data, 'showBtn', false)
    },
    //大按钮 添加组织架构
    onAddAllDeptClick() {
      this.open = true
      this.title = "添加组织"
      this.dialogType = 1
      this.agencyDept = {
        agencyId: this.agencyId,
        deptName: '',
        leader: '',
        level: 1,
        parentId: 0,
        sort: 0,
      }
    },
    //针对每级 添加组织架构
    onAddDeptClick(data) {
      this.open = true;
      this.title = "添加组织";
      this.dialogType = 1
      this.agencyDept = {
        agencyId: data.agencyId,
        deptName: '',
        leader: "",
        level: data.level + 1,
        parentId: data.agencyDeptId,
        sort: 0,
        leaderId: '',
      }
    },
    //修改组织架构
    onEditDeptClick(data) {
      this.agencyDept = data;
      this.open = true;
      this.title = "修改组织";
      this.dialogType = 2;
    },
    //确认修改、添加组织架构
    onSubmitDeptClick() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          updateAgencyDept(this.agencyDept).then(response => {
            let msg = this.dialogType == 1 ? '新增成功' : '修改成功'
            this.msgSuccess(msg);
            this.open = false;
            this.getTreeSelect();
            this.resetForm();
          });
        }
      });
    },
    //新增、修改取消点击
    oncancelDeptClick() {
      if (this.dialogType == 1) { //新增组织架构，点击取消需要清空弹框数据
        this.agencyDept.deptName = ''
        this.agencyDept.sort = 0
      }
      this.open = false;
    },
    //监听新增、修改弹框关闭
    onHandleAgencyDeptClose() {
      if (this.dialogType == 1) { //新增组织架构，弹框关闭需要清空弹框数据
        this.agencyDept.deptName = ''
        this.agencyDept.sort = 0
      }
    },
    resetForm() {
      if (this.$refs['form']!==undefined) {
        this.$refs['form'].resetFields();
        this.$refs['form'].clearValidate();
      }
    },
    //删除组织架构
    onDelDeptClick(data) {
      let self = this
      this.$confirm('是否确认删除该组织', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return delAgencyDept(data.agencyDeptId);
      }).then(() => {
        this.getTreeSelect();
        this.msgSuccess("删除成功");
      })
    },
    getAgencyDeptLevelList(level) {
      agencyDeptLevelList(level).then((response) => {
        if (response.code == 200) {
          for (let i = 0; i < response.data.length; i++) {
            let item = response.data[i]
            let obj = {}
            if (!this.isBlank(item.leaderMaps)) {
              obj = item.leaderMaps
              let arr = Object.keys(obj)
              let leaderIds = []
              let leaderNames = []
              for(let i = 0;i < arr.length;i++){
                let arrItem = arr[i]
                leaderIds.push(arrItem)
                leaderNames.push(obj[arrItem])
              }
              item.learderIds = leaderIds
              item.leaders = leaderNames
            }
          }
          this.agencyDeptList = response.data
        } else {
          this.$message(response.msg)
        }
      })
    },
    //选择负责人
    onSelectUserClick(row) {
      this.userSelect.userOpen = true;
      this.userSelect.title = row.deptName + '部门添加负责人';
      this.userSelect.agencyDeptId = row.agencyDeptId;
      this.getDeptTreeSelect();
      this.getRoleTreeSelect();
      this.getAgencyUserList();
      //修改负责人
      if (!this.isBlank(row.leaders) && !this.isBlank(row.learderIds)) {
        this.selectAgencyUserList = row.leaders
        this.leader = row.learderIds
      }

    },
    onUserTab(index) {
      this.userSelect.cur = index;
      if (this.userSelect.cur == 2) {
        this.getDeptTreeSelect();
        this.getRoleTreeSelect();
        this.getAgencyUserList();
      }
    },
    getDeptTreeSelect() {
      agencyDeptList().then(response => {
        let treeList = response.data;
        this.agencyDeptOptions = this.handleTree(treeList, "agencyDeptId");
        this.agencyDeptOptions = this.getDeptTree(this.agencyDeptOptions);
      });
    },
    getDeptTree (tree = []) {
      let arr = [];
      if (tree.length !== 0) {
        tree.forEach(item => {
          let obj = {};
          obj.label = item.deptName;
          obj.id = item.agencyDeptId;
          if(item.children) {
            obj.children = this.getDeptTree(item.children);
          }
          arr.push(obj);
        });
      }
      return arr
    },
    getRoleTreeSelect() {
      agencyRoleList().then(response => {
        let treeList = response.data;
        this.agencyRoleOptions = this.handleTree(treeList, "agencyRoleId");
        this.agencyRoleOptions = this.getRoleTree(this.agencyRoleOptions);
      });
    },
    getRoleTree (tree = []) {
      let arr = [];
      if (tree.length !== 0) {
        tree.forEach(item => {
          let obj = {};
          obj.label = item.roleName;
          obj.id = item.agencyRoleId;
          if(item.children) {
            obj.children = this.getRoleTree(item.children);
          }
          arr.push(obj);
        });
      }
      return arr
    },
    onSearchClick() {
      this.getAgencyUserList()
    },
    onResetClick() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        bindStatus: '',
        name: '',
        number: ''
      }
    },
    getAgencyUserList() {
      agencyUserList(this.queryParams).then(response => {
        if (response.code == 200) {
          this.agencyUserList = response.rows;
          this.queryParams.total = response.total;
        } else {
          this.msgError(response.msg);
        }
      });
    },
    onAllAddClick(){},
    onHandleCheckClick(row) {
      if(this.selectAgencyUserList.includes(row.name)){
        this.selectAgencyUserList = this.selectAgencyUserList.filter(function (ele){return ele != row.name;});
      }else{
        this.selectAgencyUserList.push(row.name)
      }
      if(this.leader.includes(row.agencyUserId)){
        this.leader = this.leader.filter(function (ele){return ele != row.agencyUserId;});
      }else{
        this.leader.push(row.agencyUserId)
      }
    },
    onDelSelectUser(index) {
      this.selectAgencyUserList.splice(index, 1)
      this.leader.splice(index, 1)
    },
    onConfirmAddLeaderClick() {
      agencyLeader(this.userSelect.agencyDeptId,!this.isBlank(this.leader) ? this.leader : '').then(response => {
        if (response.code == 200) {
          this.msgSuccess('设置成功');
          this.resetDialogForm();
          this.getTreeSelect()
        } else {
          this.$message(response.msg)
        }
      })
    },
    onCancelLeaderClick() {
      this.resetDialogForm()
    },
    onCloseDialogClick() {
      this.resetDialogForm()
    },
    resetDialogForm() {
      this.userSelect = {
        userOpen: false,
        title: '',
        cur: 2,
        agencyDeptId: null
      }
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        // agencyDeptId: '',
        // agencyRoleId: '',
        bindStatus: '',
        // name: '',
        // number: ''
      }
      if (this.$refs['userSelectForm']!==undefined) {
        this.$refs['userSelectForm'].resetFields();
      }
      this.agencyDeptOptions = []
      this.agencyRoleOptions = []
      this.agencyUserList = []
      this.selectAgencyUserList = []
      this.leader = []
    },
    //批量上传
    onHandleImportClick(){
      this.upload.title = "组织架构导入";
      this.upload.open = true;
    },
    handleFileUploadProgress() {},
    handleFileSuccess() {},
    handleFileRequest({ file }) {
      this.upload.file = file
    },
    onConfirmUploadClick() {
      if(this.isBlank(this.upload.file)) {
        this.$message({
          message: '请上传文件',
          type: 'warning'
        })
        return
      }
      let loading = ELEMENT.Loading.service({
        fullscreen: true,
        lock: true,
        text: '导入中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.5)'
      })
      agencyDeptExcelImport(this.upload.file).then(response => {
        if (response.code == 200) {
          loading.close()
          this.msgSuccess('导入成功')
          this.upload = {
            open: false,
            title: "",
            isUploading: false,
            file: null
          };
          this.$refs.upload.clearFiles() //上传完成后执行清空
          this.getTreeSelect()
        }
      }).catch(()=>{
        loading.close()
      })
    },
    onCancelUploadClick() {
      this.upload = {
        open: false,
        title: "",
        isUploading: false,
        file: null
      }
      this.$refs.upload.clearFiles()
    },
    onCloseUploadDialogClick() {
      this.upload = {
        open: false,
        title: "",
        isUploading: false,
        file: null
      }
      this.$refs.upload.clearFiles()
    },
    // 3.0.0.1 设置部门负责人
    onDeptLeaderClick(data) {
      let _this = this
      _this.agencyDept = data
      _this.radioIndex = (data.leaderId||'').split(",")
      _this.getListMember()
      _this.$nextTick(function () {
        _this.deptLeader.open = true
      })
    },
    onDeptLeaderTestClick(){
      this.$refs.userSelect.showDialog();
    },
    getListMember() {
      let _this = this
      _this.memberParams.agencyId = this.agencyId
      agencyUserMemberList(_this.memberParams.agencyId, _this.memberParams.searchTxt).then(response => {
        if (response.code == 200) {
          _this.memberList = response.data;
        } else {
          _this.msgError(response.msg);
        }
      });
    },
    onFocusSearchTxt() {
      this.isFocus = true
    },
    onChangeSearchTxt() {
      this.getListMember()
    },
    onClearSearchTxt() {
      if (!this.isBlank(this.memberParams.searchTxt)) {
        this.isFocus = false
        this.memberParams.searchTxt = ''
        this.getListMember()
      }
    },
    onRadioLeader(agencyUserId) {
      // console.log(index)
      if (this.radioIndex.find(x => x == agencyUserId)) {
        let index= this.radioIndex.findIndex(x=>x == agencyUserId)
        this.radioIndex.splice(index,1)
      } else {
        this.radioIndex.push(agencyUserId)
      }
    },
    onLeaderCancel(){
      this.radioIndex.splice(0)
      this.deptLeader.open = false
    },
    onLeaderClose() {
      this.radioIndex.splice(0)
      this.deptLeader.open = false
    },
    onLeaderConfirm() {
      let _this = this
      if (_this.radioIndex == null) {
        this.$message({
          type: 'warning',
          message: '请选择部门负责人～'
        })
        return
      }
      let param = {
        agencyDeptId: _this.agencyDept.agencyDeptId,
        leaderId: _this.radioIndex.join(',')
      }
      agencyDeptLeader(JSON.stringify(param)).then(response => {
        if (response.code == 200) {
          _this.msgSuccess('设置成功')
          _this.deptLeader.open = false
          _this.getTreeSelect()
        }
      })
    }
  }
};
</script>
<style scoped lang="scss">
  .app-dept{
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
  .user-tab{
    width: calc(100% + 40px);
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #eeeeee;
    margin: 0 -20px;
    li {
      width: 50%;
      text-align: center;
      font-size: 16px;
      color: #1e1e1e;
      list-style: none;
      display: inline-block;
      &.active{
        color: #1890ff;
        border-bottom: 1px solid #1890ff;
      }
    }
  }
  .userSelect-container{
    width: calc(100% + 40px);
    margin-top: -30px;
    margin-left: -20px;
    margin-right: -20px;
    .userSelect-group{
      display: inline-block;
      background: #eee;
      width: 400px;
      min-height: 800px;
      float: left;
      .userSelect-title{
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
      }
      .selected-item{
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        cursor: pointer;
        span{
          float: left;
        }
      }
    }
    .userSelect-right{
      display: inline-block;
      width: 800px;
      padding: 0 20px;
      box-sizing: border-box;
      min-height: 800px;
    }
  }
  /*新版 3.0.0.1 设置部门负责人*/
  .fx_member_select {
    margin-top: -30px;
    .select-menu{
      position: relative;
      height: 40px;
      padding: 0 10px;
      border: 1px solid #e0e0e0;
      .select-btn{
        text-align: center;
        display: inline-block;
        cursor: pointer;
        line-height: 38px;
        height: 38px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 5px;
        margin: 0 10px;
        &.select{
          color: #3476F0;
          border-bottom: solid 4px #3476F0;
        }
      }
      .select-search-pane{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 114px;
        background: #fff;
        padding: 0 10px;
        box-sizing: border-box;
        input{
          line-height: 26px !important;
          height: 26px !important;
          width: 100%;
          border: 0;
          background: #F4F4F4;
          border-radius: 13px;
          margin-top: 6px;
          padding: 0 10px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          outline: 0;
          &:focus {
            border: none;
            outline: 0;
          }
        }
      }
    }
    .select-pane{
      border: 1px solid #e0e0e0;
      border-top: none;
      height: 320px;
      .member-pane{
        .member-wrapper{
          width: 100%;
          .empty-tips{
            display: inline-block;
            margin: 10px;
            color: #989898;
            width: 100%;
            text-align: center;
          }
          .select-all-item{
            cursor: pointer;
            height: 30px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: space-between;
            -ms-flex-pack: space-between;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            padding: 0 8px 0 12px;
            border-bottom: solid 1px #e9e9e9;
            .count-label {
              color: #3476F0;
            }
          }
          .select-member{
            height: 320px;
            overflow-y: auto;
            ul {
              margin: 0;
              padding: 0;
              li {
                cursor: pointer;
                padding: 0 10px 0 10px;
                position: relative;
                line-height: 30px;
                width: 100%;
                box-sizing: border-box;
                &:hover {
                  background: #f5f7fa;
                }
                .user-name {
                  width: calc(100% - 20px);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                .select-check{
                  cursor: pointer;
                  width: 16px;
                  .icon-blank {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    border: 1px solid #91A1B7;
                    position: relative;
                    background: #fff;
                    -webkit-transition: all 218ms ease-in-out;
                    -moz-transition: all 218ms ease-in-out;
                    -o-transition: all 218ms ease-in-out;
                    transition: all 218ms ease-in-out;
                    &::before {
                      content: '';
                      display: block;
                      opacity: 0;
                      -webkit-transition: opacity 218ms ease-in-out;
                      -moz-transition: opacity 218ms ease-in-out;
                      -o-transition: opacity 218ms ease-in-out;
                      transition: opacity 218ms ease-in-out;
                      height: 6px;
                      width: 10px;
                      border-left: solid 2px #fff;
                      border-bottom: solid 2px #fff;
                      position: absolute;
                      top: 2px;
                      left: 1px;
                      -webkit-transform: rotate(-45deg);
                      -moz-transform: rotate(-45deg);
                      -ms-transform: rotate(-45deg);
                      -o-transform: rotate(-45deg);
                      transform: rotate(-45deg);
                    }
                  }
                  &.select {
                    .icon-blank {
                      border-color: #3476F0;
                      background: #3476F0;
                      &::before {
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .tree-wrapper{
          width: 100%;
          height: 320px;
          box-sizing: border-box;
          overflow-y: auto;
          padding-top: 10px;
        }
      }
    }
  }
  .dept-manager-tag{
    color: #fff;
    font-size: 12px;
    background: #3476F0;
    cursor: pointer;
    padding: 5px 8px;
    border-radius: 13px;
    margin: 0 10px;
  }
  .dept-manager-name{
    font-size: 14px;
    line-height: 50px;
  }
</style>
