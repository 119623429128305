<template>
  <div class="app-user">

    <el-row :gutter="10" class="fr" style="text-align: right; margin-bottom: 20px;">
      <el-col :span="1.5">
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="onHandleAdd">新增</el-button>
      </el-col>
    </el-row>

    <el-table :data="agencySubAdminList" style="width: 100%; cursor: pointer" ref="table" @cell-mouse-enter="onEnterTable" @cell-mouse-leave="onLeaveTable">
      <el-table-column prop="number" label="编号" align="center"></el-table-column>
      <el-table-column prop="name" label="姓名" align="center"></el-table-column>
      <el-table-column prop="date" label="绑定状态" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.bindStatus == '1' ? '未绑定' : '已绑定'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="" align="center">
        <template slot-scope="scope" v-if="scope.row.showBtn">
          <el-button size="mini" type="text" icon="el-icon-delete" @click="onHandleDelClick(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--新增子管理员用户-->
    <el-dialog title="添加子管理员" :visible.sync="open" width="650px" custom-class="add-sub-admin-dialog" append-to-body :close-on-click-modal="false" @close="onAddAdClose">
      <div class="fx_member_select">
        <ul class="select-list">
          <li v-for="(item, index) in selectAdArr" :key="index" class="select-item">
            <template v-if="item.type == 'member'">
              <svg-icon icon-class="memberIcon" style="width: 20px; height: 20px; vertical-align: -4px; margin-right: 5px;" />
            </template>
            <template v-if="item.type == 'dept'">
              <svg-icon icon-class="deptIcon" style="width: 20px; height: 20px; vertical-align: -4px; margin-right: 5px;" />
            </template>
            <template v-if="item.type == 'role'">
              <svg-icon icon-class="roleIcon" style="width: 20px; height: 20px; vertical-align: -4px; margin-right: 5px;" />
            </template>
            <span>{{item.name}}</span>
            <span class="remove-btn" @click="onRemoveSelect(item, index)">
              <svg-icon icon-class="del" style="width: 12px; height: 12px; vertical-align: -1px;" />
            </span>
          </li>
        </ul>

        <div class="select-menu">
          <div class="select-btn select">成员</div>
          <div class="select-search-pane" :style="{'width':(isFocus ? '100%' : '114px')}">
            <input type="text" v-model="memberParams.searchTxt" placeholder="搜索" @change="onChangeSearchTxt" @focus="onFocusSearchTxt" @blur="onBlurSearchTxt"></input>
            <svg-icon icon-class="del" style="width: 12px; height: 12px; position: absolute; right: 20px; top: 12px; cursor: pointer" @click="onClearSearchTxt" v-if="memberParams.searchTxt != ''" />
          </div>
        </div>
        <div class="select-pane">
          <div class="member-pane">
            <div class="select-department fl">
              <div class="depart-root" @click="onMemberClick">全部成员</div>
              <div class="x-department-tree">
                <el-tree :data="deptOptions" :props="deptTreeProps" @node-click="onHandleNodeClick"></el-tree>
              </div>
            </div>
            
            <div class="member-wrapper fr" v-if="memberList && memberList.length > 0">
              <el-alert type="warning" title="只能选择已绑定账号的用户" :closable="false" /> 
              <div class="select-all-item">
                <div class="count-label">已选 <span class="check-count">0</span>/{{memberList.length}}</div>
                <div class="check-all x-check">
                  <i class="icon-blank"></i>
                </div>
              </div>
              <div class="select-member">
                <ul>
                  <li style="background: #ebf8f7">
                    <span class="user-name fl">姓名</span>
                    <span class="user-name fl">编号</span>
                    <span class="user-name fl">部门</span>
                    <div class="select-check fr">
                    </div>
                  </li>
                  <li v-for="(item,index) in memberList" :key="item.userId" @click="onCheckBoxUser(index)">
                    <span class="user-name fl">{{item.name}}</span>
                    <span class="user-name fl">{{item.number}}</span>
                    <span class="user-name fl">{{(item.agencyUserDeptNames||[]).join(",")}}</span>
                    <div class="select-check fr" :class="{select:item.checked}">
                      <i class="icon-blank"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="member-wrapper fr" v-else>
              <span class="empty-tips">没有可选成员<br>(只能选择已绑定账号的用户)</span>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" @click="onAddAdConfirm">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import { agencyDeptList, agencyUserMemberList, agencySubAdmin, agencySubAdminList, delAgencySubAdmin } from "@/api/system/agency"

export default {
  name: 'User',
  components: { Treeselect },
  props: {
    cur: {
      type: String|Number
    },
    agencyId: {
      type: String|Number
    },
  },
  data () {
    return {
      agencySubAdminList: [],
      open: false,
      deptOptions: [], //部门树形
      deptTreeProps: {
        children: "children",
        label: "deptName"
      },
      selectAdArr: [],
      memberList: [],
      memberParams: {
        agencyId: null,
        searchTxt: ''
      },
      isFocus: false
    }
  },
  computed: {},
  created () {
    this.getAgencySubAdminList()
  },
  methods: {
    // 子管理员列表
    getAgencySubAdminList () {
      agencySubAdminList({
        pageNum: 1,
        pageSize: 150
      }).then(response => {
        if (response.code == 200) {
          for (let i = 0; i < response.rows.length; i++) {
            response.rows[i].showBtn = false
          }
          this.agencySubAdminList = response.rows;
        } else {
          this.msgError(response.msg);
        }
      });
    },
    onEnterTable (row, column, cell, event) {
      row.showBtn = true
      this.$forceUpdate()
    },
    onLeaveTable (row, column, cell, event) {
      row.showBtn = false
      this.$forceUpdate()
    },
    onHandleDelClick (scope) {
      console.log(scope)
      let _this = this
      let agencyUserIds = []
      agencyUserIds.push(scope.agencyUserId)
      let param = {
        agencyUserIds: agencyUserIds
      }
      _this.$confirm('确认删除该子管理员？', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delAgencySubAdmin(param)
      }).then(() => {
        _this.$message({
          message: '删除子管理员成功',
          type: 'success'
        })
        _this.agencySubAdminList = []
        _this.getAgencySubAdminList()
      }).catch(function () { })
    },
    // 添加子管理员
    onHandleAdd () {
      this.open = true
      this.memberParams.searchTxt = ''
      this.memberParams.agencyDeptId = undefined
      this.isFocus = false
      this.getDeptTreeSelect()
      this.getListMember()
    },
    getDeptTreeSelect () {
      agencyDeptList().then(response => {
        let treeList = response.data;
        this.deptOptions = this.handleTree(treeList, "agencyDeptId");
      });
    },
    getDeptTree (tree = []) {
      let arr = [];
      if (tree.length !== 0) {
        tree.forEach(item => {
          let obj = {};
          obj.label = item.deptName;
          obj.id = item.agencyDeptId;
          if (item.children) {
            obj.children = this.getDeptTree(item.children);
          }
          arr.push(obj);
        });
      }
      return arr
    },
    getListMember () {
      let _this = this
      let agencyId = !_this.isBlank(_this.memberParams.agencyId) ? _this.memberParams.agencyId : _this.agencyId
      agencyUserMemberList(agencyId, _this.memberParams.searchTxt, _this.memberParams.agencyDeptId).then(response => {
        if (response.code == 200) {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].checked = false
          }
          _this.memberList = response.data.filter(x => x.bindStatus === "2");
        } else {
          _this.msgError(response.msg);
        }
      });
    },
    onChangeSearchTxt () {
      this.getListMember()
    },
    onFocusSearchTxt () {
      this.isFocus = true
    },
    onBlurSearchTxt () {
      if (this.memberParams.searchTxt === '') {
        this.isFocus = false
        this.memberParams.searchTxt = ''
        this.getListMember()
      }
    },
    onClearSearchTxt () {
      if (!this.isBlank(this.memberParams.searchTxt)) {
        this.isFocus = false
        this.memberParams.searchTxt = ''
        this.getListMember()
      }
    },
    onMemberClick () {
      this.memberParams.agencyDeptId = undefined
      this.getListMember()
    },
    onHandleNodeClick (data) {
      this.memberParams.agencyDeptId = data.agencyDeptId
      this.getListMember()
    },
    onCheckBoxUser (index) {
      this.memberList[index].checked = !this.memberList[index].checked
      if (this.memberList[index].checked) {
        this.selectAdArr.push({
          name: this.memberList[index].name,
          agencyUserId: this.memberList[index].agencyUserId
        })
      } else {
        let findIndex = this.selectAdArr.findIndex((value) => value.name == this.memberList[index].name);
        if (findIndex != -1) {
          this.selectAdArr.splice(findIndex, 1)
        }
      }
    },
    onRemoveSelect (item, index) {
      this.selectAdArr.splice(index, 1)
      let findIndex = this.memberList.findIndex((value) => value.name == item.name);
      if (findIndex != -1) {
        this.memberList[findIndex].checked = !this.memberList[findIndex].checked
      }
    },
    onAddAdConfirm () {
      let _this = this
      if (_this.isBlank(_this.selectAdArr)) {
        _this.$message({
          message: '请选择用户设置子管理员!',
          type: 'warning'
        })
        return false
      }
      let agencyUserIds = []
      for (let i = 0; i < _this.selectAdArr.length; i++) {
        agencyUserIds.push(_this.selectAdArr[i].agencyUserId)
      }
      let param = {
        agencyUserIds: agencyUserIds
      }
      agencySubAdmin(param).then(response => {
        if (response.code == 200) {
          _this.msgSuccess('新增成功');
          _this.open = false;
          _this.memberParams.searchTxt = ''
          _this.memberParams.agencyDeptId = undefined
          _this.isFocus = false
          _this.memberList = []
          _this.getAgencySubAdminList()
        } else {
          _this.msgError(response.msg)
        }
      })
    },
    onAddAdClose () {
      this.open = false
      this.memberParams.searchTxt = ''
      this.memberParams.agencyDeptId = undefined
      this.isFocus = false
      this.memberList = []
    },
  }
};
</script>
<style lang="scss">
.app-user {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
}
.vue-treeselect__control {
  height: 28px;
  border-radius: 4px;
}
.vue-treeselect {
  line-height: 26px;
  margin-top: 5px;
  font-size: 12px;
}
.vue-treeselect__placeholder,
.vue-treeselect__single-value {
  line-height: 26px;
}
.el-table .cell {
  height: 23px;
}
.fx_member_select {
  margin-top: -30px;
  .select-list {
    height: 80px;
    border: 1px solid #e0e0e0;
    margin-bottom: 10px;
    overflow: auto;
    margin-top: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      line-height: 30px;
      margin: 5px 0 0 5px;
      padding: 0 10px;
      border-radius: 1px;
      background: #f3f6fc;
      cursor: pointer;
    }
  }
  .select-menu {
    position: relative;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #e0e0e0;
    .select-btn {
      text-align: center;
      display: inline-block;
      cursor: pointer;
      line-height: 38px;
      height: 38px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 5px;
      margin: 0 10px;
      &.select {
        color: #3476f0;
        border-bottom: solid 4px #3476f0;
      }
    }
    .select-search-pane {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 114px;
      background: #fff;
      padding: 0 10px;
      box-sizing: border-box;
      input {
        line-height: 26px !important;
        height: 26px !important;
        width: 100%;
        border: 0;
        background: #f4f4f4;
        border-radius: 13px;
        margin-top: 6px;
        padding: 0 10px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        outline: 0;
        &:focus {
          border: none;
          outline: 0;
        }
      }
    }
  }
  .select-pane {
    border: 1px solid #e0e0e0;
    border-top: none;
    height: 320px;
    .member-pane {
      .select-department {
        border-right: solid 1px #e0e0e0;
        width: 35%;
        box-sizing: border-box;
        height: 320px;
        .depart-root {
          background: #ebf8f7;
          text-indent: 10px;
          line-height: 30px;
          cursor: pointer;
        }
        .x-department-tree {
          height: 290px;
          overflow-y: auto;
        }
      }
      .member-wrapper {
        width: 65%;
        .empty-tips {
          display: inline-block;
          margin: 10px;
          width:100%;
          padding: 10px 0px;
          line-height: 25px;
          text-align: center;
          color: #989898;
        }
        .select-all-item {
          cursor: pointer;
          height: 30px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: space-between;
          -ms-flex-pack: space-between;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          padding: 0 8px 0 12px;
          border-bottom: solid 1px #e9e9e9;
          .count-label {
            color: #3476f0;
          }
        }
        .select-member {
          height: 255px;
          overflow-y: auto;
          ul {
            margin: 0;
            padding: 0;
            li {
              cursor: pointer;
              padding: 0 10px 0 10px;
              position: relative;
              line-height: 30px;
              width: 100%;
              box-sizing: border-box;
              display:flex;
              justify-content: space-between;
              align-items: center;
              &:hover {
                background: #f5f7fa;
              }
              .user-name {
                width: 28%;
                flex-grow:1;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .select-check {
                cursor: pointer;
                width: 20px;
                flex-shrink: 0;
                flex-grow:0;
                text-align: right;
                .icon-blank {
                  display: inline-block;
                  width: 14px;
                  height: 14px;
                  border: 1px solid #91a1b7;
                  position: relative;
                  background: #fff;
                  -webkit-transition: all 218ms ease-in-out;
                  -moz-transition: all 218ms ease-in-out;
                  -o-transition: all 218ms ease-in-out;
                  transition: all 218ms ease-in-out;
                  &::before {
                    content: '';
                    display: block;
                    opacity: 0;
                    -webkit-transition: opacity 218ms ease-in-out;
                    -moz-transition: opacity 218ms ease-in-out;
                    -o-transition: opacity 218ms ease-in-out;
                    transition: opacity 218ms ease-in-out;
                    height: 6px;
                    width: 10px;
                    border-left: solid 2px #fff;
                    border-bottom: solid 2px #fff;
                    position: absolute;
                    top: 2px;
                    left: 1px;
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                  }
                }
                &.select {
                  .icon-blank {
                    border-color: #3476f0;
                    background: #3476f0;
                    &::before {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .tree-wrapper {
        width: 100%;
        height: 320px;
        box-sizing: border-box;
        overflow-y: auto;
        padding-top: 10px;
      }
    }
  }
}

.add-sub-admin-dialog{
  .el-dialog__body{
    padding-bottom:0px;
  }
}
</style>
