<template>
  <div class="app-user">

    <el-form :inline="true" label-position="right">
      <el-row>
        <el-col :span="4">
          <el-form-item label="">
            <el-input v-model="queryParams.number" placeholder="请输入编号" clearable size="mini"/>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="">
            <el-input v-model="queryParams.name" placeholder="请输入姓名" clearable size="mini"/>
          </el-form-item>
        </el-col>
<!--        <el-col :span="4">-->
<!--          <el-form-item label="" prop="values">-->
<!--            <treeselect v-model="queryParams.agencyRoleId" :options="roleOptions" :multiple="false" :flat="false" :show-count="true" :default-expand-level="1" :disable-branch-nodes="true" placeholder="所属角色" size="mini"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="4">
          <el-form-item label="">
            <treeselect v-model="queryParams.agencyDeptId" :options="deptOptions" :multiple="false" :flat="false" :show-count="true" :default-expand-level="1" placeholder="归属部门" size="mini"/>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="">
            <el-select v-model="queryParams.bindStatus" placeholder="绑定状态" clearable size="mini">
              <el-option label="未绑定" value="1"></el-option>
              <el-option label="已绑定" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-form-item>
          <el-button class="filter-item" type="primary" icon="el-icon-search" size="mini" @click="onHandleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="onResetQuery">重置</el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <el-row :gutter="10" class="fr" style="text-align: right; margin-bottom: 20px;">
      <el-col :span="1.5">
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="onHandleAdd">新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="warning" icon="el-icon-upload" size="mini" @click="onHandleImportClick">批量导入</el-button>
      </el-col>
    </el-row>

    <el-table :data="agencyUserList" style="width: 100%; cursor: pointer" @cell-mouse-enter="onEnterTable" @cell-mouse-leave="onLeaveTable">
      <el-table-column prop="number" label="编号" align="center"></el-table-column>
      <el-table-column prop="name" label="姓名" align="center"></el-table-column>
      <el-table-column prop="username" label="登录账号" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.username ? scope.row.username : '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dept" label="部门" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <template v-for="(deptItem, deptIndex) in scope.row.agencyUserDeptNames" v-if="scope.row.agencyUserDeptNames && scope.row.agencyUserDeptNames.length > 0">
            <span>{{deptItem}}</span>
            <span v-if="deptIndex < scope.row.agencyUserDeptNames.length - 1">、</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="role" label="角色" align="center" show-overflow-tooltip>
        <template slot-scope="scope" v-if="scope.row.agencyUserRoleNames && scope.row.agencyUserRoleNames.length > 0">
          <template v-for="(roleItem,roleIndex) in scope.row.agencyUserRoleNames">
            <span>{{roleItem}}</span>
            <span v-if="roleIndex < scope.row.agencyUserRoleNames.length - 1">、</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="绑定状态" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.bindStatus == '1' ? '未绑定' : '已绑定'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="" align="center">
        <template slot-scope="scope" v-if="scope.row.showBtn">
          <el-button size="mini" type="text" icon="el-icon-view" @click="onHandleDetailClick(scope.row)">查看</el-button>
          <el-button size="mini" type="text" icon="el-icon-edit"  @click="onHandleUpdateClick(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getAgencyUserList"/>

    <!--新增、修改、查看机构用户-->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body @close="onCloseDialogClick">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户编号" prop="number">
              <el-input v-model="form.number" placeholder="请输入用户编号" :disabled="isDetail"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入用户名称" :disabled="isDetail"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="归属部门" prop="agencyDeptIds">
              <treeselect v-model="form.agencyDeptIds" :options="deptOptions" :multiple="true" :show-count="true" :flat="true" :default-expand-level="1" placeholder="请选择归属部门" :disabled="isDetail"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="所属角色" prop="agencyRoleIds">
              <treeselect v-model="form.agencyRoleIds" :options="roleOptions" :multiple="true" :show-count="true" :flat="true" :default-expand-level="1" :disable-branch-nodes="true" placeholder="请选择所属角色" :disabled="isDetail"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="!isDetail">
        <el-button type="primary" @click="onSubmitFormClick">确 定</el-button>
        <el-button @click="onCancelClick">取 消</el-button>
      </div>
    </el-dialog>

    <!--用户导入对话框-->
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="400px" append-to-body @close="onCloseUploadDialogClick">
      <el-upload ref="upload" :limit="1" accept=".xlsx, .xls" :headers="upload.headers" action="" v-model="upload.file"
                 :disabled="upload.isUploading" :on-progress="handleFileUploadProgress" :on-success="handleFileSuccess" :http-request="handleFileRequest" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip" style="margin-top: 5px; line-height: 16px;">
          <el-button type="text" style="font-size: 12px;"><a href='https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/%E6%9C%BA%E6%9E%84%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx' download="机构用户导入模板.xlsx">下载模板</a></el-button>
<!--          <el-link type="info" style="font-size:12px" href='https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/%E6%9C%BA%E6%9E%84%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx' download="机构用户导入模板.xlsx">下载模板</el-link>-->
          <div class="el-upload__tip" style="color:red; display: inline-block; margin-top: 0; margin-left: 5px;" slot="tip">提示：仅允许导入“xls”或“xlsx”格式文件！</div>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmUploadClick">确 定</el-button>
        <el-button @click="onCancelUploadClick">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import {agencyDeptList, agencyRoleList, updateAgencyUser, agencyUserList, agencyUserExcelImport} from "@/api/system/agency"
import {getSessionStorageObj, setSessionStorageObj} from "@/utils/db"
import ELEMENT from "element-ui"

export default {
  name: 'User',
  components: { Treeselect },
  props: {
    cur: {
      type: String
    }
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        bindStatus: '',
        name: '',
        number: '',
        roleName: '学生'
      },
      total: 0, //总页数
      agencyUserList: [],
      form: {},
      rules: {
        number: [
          { required: true, message: "用户编号不能为空", trigger: "blur" }
        ],
        name: [
          { required: true, message: "用户名称不能为空", trigger: "blur" }
        ],
      },
      open: false,
      title: '',
      deptOptions: [], //部门树形
      deptTreeProps: {
        children: "children",
        label: "deptName"
      },
      roleOptions: [],//角色树形
      roleTreeProps: {
        children: "children",
        label: "roleName"
      },
      isDetail: false, //是否查看table每一项的详情
      // 用户导入参数
      upload: {
        open: false,
        title: "",
        isUploading: false, // 是否禁用上传
        file: null
      }
    }
  },
  computed: {},
  created() {
    this.getAgencyUserList();
    this.getDeptTreeSelect(); //部门
    this.getRoleTreeSelect(); //角色
  },
  methods: {
    //搜索
    onHandleQuery() {
      this.getAgencyUserList()
    },
    //重置
    onResetQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        bindStatus: '',
        name: '',
        number: '',
        roleName: '学生'
      }
      this.deptOptions = []
      this.roleOptions = []
      this.getAgencyUserList()
      this.getDeptTreeSelect()
      this.getRoleTreeSelect()
    },
    //新增用户
    onHandleAdd() {
      this.resetForm();
      this.open = true;
      this.title = "添加用户";
      this.getDeptTreeSelect(); //部门
      this.getRoleTreeSelect(); //角色
    },
    resetForm() {
      this.form = {
        name: '',
        number: '',
      }
      if (this.$refs['form']!==undefined) {
        this.$refs['form'].resetFields();
        this.$refs['form'].clearValidate();
      }
    },
    getDeptTreeSelect() {
      agencyDeptList().then(response => {
        let treeList = response.data;
        this.deptOptions = this.handleTree(treeList, "agencyDeptId");
        this.deptOptions = this.getDeptTree(this.deptOptions);
      });
    },
    getDeptTree (tree = []) {
      let arr = [];
      if (tree.length !== 0) {
        tree.forEach(item => {
          let obj = {};
          obj.label = item.deptName;
          obj.id = item.agencyDeptId;
          if(item.children) {
            obj.children = this.getDeptTree(item.children);
          }
          arr.push(obj);
        });
      }
      return arr
    },
    getRoleTreeSelect() {
      agencyRoleList().then(response => {
        let treeList = response.data;
        this.roleOptions = this.handleTree(treeList, "agencyRoleId");
        this.roleOptions = this.getRoleTree(this.roleOptions);
      });
    },
    getRoleTree (tree = []) {
      let arr = [];
      if (tree.length !== 0) {
        tree.forEach(item => {
          let obj = {};
          obj.label = item.roleName;
          obj.id = item.agencyRoleId;
          if(item.children) {
            obj.children = this.getRoleTree(item.children);
          }
          arr.push(obj);
        });
      }
      return arr
    },
    onSubmitFormClick() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let agencyUserInsertVo = {}
          agencyUserInsertVo.name = this.form.name
          agencyUserInsertVo.number = this.form.number
          let agencyDeptIds = [] //部门ids
          let agencyRoleIds = [] //角色ids
          if(!this.isBlank(this.form.agencyDeptIds)) {
            for(let i = 0; i < this.form.agencyDeptIds.length; i++) {
              agencyDeptIds.push(this.form.agencyDeptIds[i])
            }
            agencyUserInsertVo.agencyDeptIds = agencyDeptIds
          }
          if(!this.isBlank(this.form.agencyRoleIds)) {
            for(let i = 0; i < this.form.agencyRoleIds.length; i++) {
              agencyRoleIds.push(this.form.agencyRoleIds[i])
            }
            agencyUserInsertVo.agencyRoleIds = agencyRoleIds
          }
          if (!this.isBlank(this.form.agencyUserId)) {
            agencyUserInsertVo.agencyUserId = this.form.agencyUserId
          }
          let self = this
          updateAgencyUser(agencyUserInsertVo).then(response => {
            if (response.code == 200) {
              let msg = !this.isBlank(agencyUserInsertVo.agencyUserId) ? '修改成功' : '新增成功'
              self.msgSuccess(msg);
              self.open = false;
              self.getAgencyUserList();
            } else {
              this.msgError(response.msg);
            }
          })
        }

      })
    },
    onCancelClick() {
      this.resetForm()
      this.open = false
    },
    onCloseDialogClick() {
      this.resetForm()
    },
    getAgencyUserList() {
      agencyUserList(this.queryParams).then(response => {
        if (response.code == 200) {
          for(let i = 0; i < response.rows.length; i++) {
            response.rows[i].showBtn = false
          }
          this.agencyUserList = response.rows;
          this.total = response.total;
        } else {
          this.msgError(response.msg);
        }
      });
    },
    onEnterTable(row, column, cell, event) {
      row.showBtn = true
    },
    onLeaveTable(row, column, cell, event) {
      row.showBtn = false
    },
    //修改机构用户
    onHandleUpdateClick(row) {
      this.isDetail = false;
      this.resetForm();
      this.open = true;
      this.title = "修改用户";
      this.getDeptTreeSelect(); //部门
      this.getRoleTreeSelect(); //角色
      this.form.name = row.name;
      this.form.number = row.number;
      if (!this.isBlank(row.agencyUserDeptIds)) {
        this.form.agencyDeptIds = row.agencyUserDeptIds;
      }
      if (!this.isBlank(row.agencyUserRoleIds)) {
        this.form.agencyRoleIds = row.agencyUserRoleIds;
      }
      this.form.agencyUserId = row.agencyUserId
    },
    //查看用户详情
    onHandleDetailClick(row) {
      this.isDetail = true;
      this.resetForm();
      this.open = true;
      this.title = "查看用户";
      this.getDeptTreeSelect(); //部门
      this.getRoleTreeSelect(); //角色
      this.form.name = row.name;
      this.form.number = row.number;
      if (!this.isBlank(row.agencyUserDeptIds)) {
        this.form.agencyDeptIds = row.agencyUserDeptIds;
      }
      if (!this.isBlank(row.agencyUserRoleIds)) {
        this.form.agencyRoleIds = row.agencyUserRoleIds;
      }
    },
    //批量上传
    onHandleImportClick(){
      this.upload.title = "用户导入";
      this.upload.open = true;
      this.upload.isUploading = false
      this.upload.file = null
    },
    handleFileUploadProgress() {},
    handleFileSuccess() {},
    handleFileRequest({ file }) {
      this.upload.file = file
    },
    onConfirmUploadClick() {
      if(this.isBlank(this.upload.file)) {
        this.$message({
          message: '请上传文件',
          type: 'warning'
        })
        return
      }
      let loading = ELEMENT.Loading.service({
        fullscreen: true,
        lock: true,
        text: '导入中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.5)'
      })
      agencyUserExcelImport(this.upload.file).then(response => {
        if (response.code == 200) {
          loading.close()
          this.msgSuccess('导入成功')
          this.upload = {
            open: false,
            title: "",
            isUploading: false,
            file: null
          }
          this.$refs.upload.clearFiles() //上传完成后执行清空
          this.getAgencyUserList()
        }
      }).catch((ex)=>{
          loading.close()
          console.log(ex);
      })
    },
    onCancelUploadClick() {
      this.upload = {
        open: false,
        title: "",
        isUploading: false,
        file: null
      }

      this.$refs.upload.clearFiles()
    },
    onCloseUploadDialogClick() {
      this.upload = {
        open: false,
        title: "",
        isUploading: false,
        file: null
      }
      this.$refs.upload.clearFiles()
    }
  }
};
</script>
<style lang="scss">
.app-user{
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
}
.vue-treeselect__control{
  height: 28px;
  border-radius: 4px;
}
.vue-treeselect{
  line-height: 26px;
  margin-top: 5px;
  font-size: 12px;
}
.vue-treeselect__placeholder, .vue-treeselect__single-value{
  line-height: 26px;
}
.el-table .cell {
  height: 23px;
}
</style>
