var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-user" },
    [
      _c(
        "el-form",
        { attrs: { inline: true, "label-position": "right" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入编号",
                          clearable: "",
                          size: "mini",
                        },
                        model: {
                          value: _vm.queryParams.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "number", $$v)
                          },
                          expression: "queryParams.number",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入姓名",
                          clearable: "",
                          size: "mini",
                        },
                        model: {
                          value: _vm.queryParams.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "name", $$v)
                          },
                          expression: "queryParams.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("treeselect", {
                        attrs: {
                          options: _vm.deptOptions,
                          multiple: false,
                          flat: false,
                          "show-count": true,
                          "default-expand-level": 1,
                          placeholder: "归属部门",
                          size: "mini",
                        },
                        model: {
                          value: _vm.queryParams.agencyDeptId,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "agencyDeptId", $$v)
                          },
                          expression: "queryParams.agencyDeptId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "绑定状态",
                            clearable: "",
                            size: "mini",
                          },
                          model: {
                            value: _vm.queryParams.bindStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "bindStatus", $$v)
                            },
                            expression: "queryParams.bindStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "未绑定", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "已绑定", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.onHandleQuery },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.onResetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "fr",
          staticStyle: { "text-align": "right", "margin-bottom": "20px" },
          attrs: { gutter: 10 },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.onHandleAdd },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    icon: "el-icon-upload",
                    size: "mini",
                  },
                  on: { click: _vm.onHandleImportClick },
                },
                [_vm._v("批量导入")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", cursor: "pointer" },
          attrs: { data: _vm.agencyUserList },
          on: {
            "cell-mouse-enter": _vm.onEnterTable,
            "cell-mouse-leave": _vm.onLeaveTable,
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "number", label: "编号", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "姓名", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "username", label: "登录账号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.username ? scope.row.username : "--")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "dept",
              label: "部门",
              align: "center",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._l(
                      scope.row.agencyUserDeptNames,
                      function (deptItem, deptIndex) {
                        return scope.row.agencyUserDeptNames &&
                          scope.row.agencyUserDeptNames.length > 0
                          ? [
                              _c("span", [_vm._v(_vm._s(deptItem))]),
                              deptIndex <
                              scope.row.agencyUserDeptNames.length - 1
                                ? _c("span", [_vm._v("、")])
                                : _vm._e(),
                            ]
                          : _vm._e()
                      }
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "role",
              label: "角色",
              align: "center",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.agencyUserRoleNames &&
                      scope.row.agencyUserRoleNames.length > 0
                      ? [
                          _vm._l(
                            scope.row.agencyUserRoleNames,
                            function (roleItem, roleIndex) {
                              return [
                                _c("span", [_vm._v(_vm._s(roleItem))]),
                                roleIndex <
                                scope.row.agencyUserRoleNames.length - 1
                                  ? _c("span", [_vm._v("、")])
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c("el-table-column", {
            attrs: { prop: "date", label: "绑定状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.bindStatus == "1" ? "未绑定" : "已绑定"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "", align: "center" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.showBtn
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "text",
                                icon: "el-icon-view",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onHandleDetailClick(scope.row)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "text",
                                icon: "el-icon-edit",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onHandleUpdateClick(scope.row)
                                },
                              },
                            },
                            [_vm._v("修改")]
                          ),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getAgencyUserList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
            close: _vm.onCloseDialogClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户编号", prop: "number" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入用户编号",
                              disabled: _vm.isDetail,
                            },
                            model: {
                              value: _vm.form.number,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "number", $$v)
                              },
                              expression: "form.number",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户名称", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入用户名称",
                              disabled: _vm.isDetail,
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "归属部门", prop: "agencyDeptIds" } },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.deptOptions,
                              multiple: true,
                              "show-count": true,
                              flat: true,
                              "default-expand-level": 1,
                              placeholder: "请选择归属部门",
                              disabled: _vm.isDetail,
                            },
                            model: {
                              value: _vm.form.agencyDeptIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "agencyDeptIds", $$v)
                              },
                              expression: "form.agencyDeptIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属角色", prop: "agencyRoleIds" } },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.roleOptions,
                              multiple: true,
                              "show-count": true,
                              flat: true,
                              "default-expand-level": 1,
                              "disable-branch-nodes": true,
                              placeholder: "请选择所属角色",
                              disabled: _vm.isDetail,
                            },
                            model: {
                              value: _vm.form.agencyRoleIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "agencyRoleIds", $$v)
                              },
                              expression: "form.agencyRoleIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isDetail
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onSubmitFormClick },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c("el-button", { on: { click: _vm.onCancelClick } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.upload.title,
            visible: _vm.upload.open,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.upload, "open", $event)
            },
            close: _vm.onCloseUploadDialogClick,
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              attrs: {
                limit: 1,
                accept: ".xlsx, .xls",
                headers: _vm.upload.headers,
                action: "",
                disabled: _vm.upload.isUploading,
                "on-progress": _vm.handleFileUploadProgress,
                "on-success": _vm.handleFileSuccess,
                "http-request": _vm.handleFileRequest,
                drag: "",
              },
              model: {
                value: _vm.upload.file,
                callback: function ($$v) {
                  _vm.$set(_vm.upload, "file", $$v)
                },
                expression: "upload.file",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  staticStyle: { "margin-top": "5px", "line-height": "16px" },
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "font-size": "12px" },
                      attrs: { type: "text" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/%E6%9C%BA%E6%9E%84%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx",
                            download: "机构用户导入模板.xlsx",
                          },
                        },
                        [_vm._v("下载模板")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      staticStyle: {
                        color: "red",
                        display: "inline-block",
                        "margin-top": "0",
                        "margin-left": "5px",
                      },
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("提示：仅允许导入“xls”或“xlsx”格式文件！")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmUploadClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelUploadClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }