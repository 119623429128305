<template>
  <div class="container">
    <!-- <div class="header-wrapper">
      <header class="header-container">
        <div class="header-breadcrumb">
          <router-link class="private-home-link" to="/index">
            <span class="home-icon">
              <svg-icon icon-class="myHome" style="width: 18px; height: 18px; vertical-align: -2px;"/>
            </span>
            <span>首页</span>
          </router-link>
          <svg-icon icon-class="myRightArrow" style="width: 18px; height: 18px; vertical-align:middle;" class="separator"/>
          <span class="headerNow">机构管理</span>
        </div>
        <div class="header-actions">
          <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
            <div class="avatar-wrapper">
              <img :src="userInfo.avatarUrl ? userInfo.avatarUrl : 'https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/profile.jpg'" class="user-avatar">
              <i class="el-icon-caret-bottom" />
            </div>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/user/profile">
                <el-dropdown-item>个人中心</el-dropdown-item>
              </router-link>
              <el-dropdown-item divided @click.native="logout">
                <span>退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </header>
    </div> -->
    <div class="clearfix app-container">
      <div class="agency-left fl left-menu">
        <ul class="tab-title">
          <li v-if="userInfo.type == '2'" @click="onTabClick(1)" :class="{active:cur==1}">
            <svg-icon icon-class="myInfo" class="tab-title-icon"/>
            机构信息
          </li>
          <li v-if="userInfo.type == '2'" @click="onTabClick(2)" :class="{active:cur==2}">
            <svg-icon icon-class="myVersion" class="tab-title-icon"/>
            版本信息
          </li>
          <li v-if="userInfo.type == '2'" @click="onTabClick(8)" :class="{active:cur==8}">
            <svg-icon icon-class="mySubAd" class="tab-title-icon"/>
            子管理员
          </li>
          <li v-if="userInfo.type == '2' || agencyUser.type == '3'" @click="onTabClick(3)" :class="{active:cur==3}">
            <svg-icon icon-class="myAgency" class="tab-title-icon"/>
            组织架构
          </li>
          <li v-if="userInfo.type == '2' || agencyUser.type == '3'" @click="onTabClick(4)" :class="{active:cur==4}">
            <svg-icon icon-class="myRole" class="tab-title-icon"/>
            角色管理
          </li>
          <li v-if="userInfo.type == '2' || agencyUser.type == '3'" @click="onTabClick(5)" :class="{active:cur==5}">
            <svg-icon icon-class="myUser" class="tab-title-icon"/>
            用户管理
          </li>
          <!-- <li @click="onTabClick(6)" :class="{active:cur==6}">
            <svg-icon icon-class="myStudent" style="width: 18px; height: 18px; margin-right: 5px; vertical-align: -4px;"/>
            学生管理
          </li>
          <li @click="onTabClick(7)" :class="{active:cur==7}">
            <svg-icon icon-class="myTeacher" style="width: 18px; height: 18px; margin-right: 5px; vertical-align: -4px;"/>
            教师管理
          </li> -->
        </ul>
      </div>
      <div style="width:20px;flex-shrink:0"></div>
      <div class="agency-right fr">
        <div class="tab-content" v-if="cur == 1">
          <div class="fx-corp-info-panel">
            <div class="agency-panel-title">机构名称</div>
             <div class="fx-row-info-panel">
              <div class="label">
                <img :src="agency.logo" class="agencyLogo">
              </div>
              <div class="content">
                <span class="content-row">{{agency.name}}</span>
              </div>
            </div>
            <!-- <div class="fx-row-info-panel">
              <div class="label">LOGO</div>
              <div class="content">
                <img :src="agency.logo" class="agencyLogo">
              </div>
            </div>
            <div class="fx-row-info-panel">
              <div class="label">机构名称</div>
              <div class="content">

              </div>
            </div> -->
          </div>
          <div class="fx-agency-info-panel" v-if="dashboardInfo">
            <div class="agency-panel-title">机构概览</div>
            <div class="agency-chart-container">
              <div class="agency-chart-item">
                <span class="current-amount">{{dashboardInfo.projectNum}}</span>
                <span class="sum">应用数量</span>
              </div>
              <div class="agency-chart-item">
                <span class="current-amount">{{dashboardInfo.projectFormNum}}</span>
                <span class="sum">应用内表单数量</span>
              </div>
              <div class="agency-chart-item">
                <span class="current-amount">{{dashboardInfo.formNum}}</span>
                <span class="sum">独立表单数量</span>
              </div>
              <div class="agency-chart-item">
                <span class="current-amount">{{dashboardInfo.totalFillNum}}</span>
                <span class="sum">已提交数据总量</span>
              </div>
              <div class="agency-chart-item">
                <span class="current-amount">{{dashboardInfo.currentMonthFillNum}}</span>
                <span class="sum">当月提交数据总量</span>
              </div>
              <div class="agency-chart-item">
                <span class="current-amount">{{dashboardInfo.todayFillNum}}</span>
                <span class="sum">当日提交数据总量</span>
              </div>
              <div class="agency-chart-item">
                <span class="current-amount">{{dashboardInfo.totalFileNum}}</span>
                <span class="sum">附件总数量</span>
              </div>
              <div class="agency-chart-item">
                <span class="current-amount">{{dashboardInfo.totalFileSize}} MB</span>
                <span class="sum">附件上传总量</span>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-content" v-if="cur == 2">
          <div class="fx-version-info-panel">
            <div class="version-wrapper">
              <div class="label">会员版本</div>
              <div class="content">
                <span class="version-name">机构免费版</span>
              </div>
            </div>
            <div class="privilege-wrapper" style="margin-top:16px;">
              <div class="label">系统容量</div>
              <div class="content" style="margin: 0">
                <div class="privilege-item">
                  <div class="name-wrapper">
                    <svg-icon icon-class="privilegeUser" style="width: 22px; height: 22px; vertical-align: -5px"/>
                    <span class="name-text">用户量</span>
                  </div>
                  <div class="progress-bar">
                    <div class="progress-wrapper">
                      <div class="value-bar"></div>
                    </div>
                  </div>
                  <div class="content-wrapper">
                    <div class="content-left fl">已用：{{versionDashboardInfo.agencyUserNum}}人</div>
                    <div class="content-right fr">总量：无限</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-content tab-content-bg" v-if="cur == 3">
          <Dept :agencyId="agency.agencyId"/>
        </div>
        <div class="tab-content tab-content-bg" v-if="cur == 4">
          <Role :agencyId="agency.agencyId"/>
        </div>
        <div class="tab-content tab-content-bg" v-if="cur == 5">
          <user :cur="cur"/>
        </div>
        <div class="tab-content tab-content-bg" v-if="cur == 6">
          <student :cur="cur"/>
        </div>
        <div class="tab-content tab-content-bg" v-if="cur == 7">
          <teacher :cur="cur"/>
        </div>
        <div class="tab-content tab-content-bg" v-if="cur == 8">
          <subAd :cur="cur" :agencyId="agency.agencyId"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from "./user"
import student from "./student"
import teacher from "./teacher"
import Role from "./role"
import Dept from "./dept"
import subAd from "./subAd"
import { agencyInfo, agencyDashboard, versionDashboard, getAgencyUser } from "@/api/system/agency"
import {globalBus} from "@/utils/globalBus";

export default {
  name: "User",
  components: {
    user,
    student,
    teacher,
    Role,
    Dept,
    subAd
  },
  data() {
    return {
      cur: 1,
      agency: {},
      userInfo: {},
      dashboardInfo: {},
      versionDashboardInfo: {},
      agencyUser: {}
    }
  },
  watch: {},
  computed: {},
  created() {
    this.getUserInfo()
    this.getAgencyInfo()
    this.getDashboardInfo()
    this.getVersionDashboard()
    //在个人中心修改头像时 需要同步修改
    globalBus.$on('refreshUser', (data) => {
      this.getUserInfo()
    })
  },
  methods: {
    onTabClick(index) {
      this.cur = index
    },
    getAgencyInfo() {
      agencyInfo().then((response) => {
        if (response.code == 200) {
          this.agency = response.data
        }
      })
    },
    getDashboardInfo() {
      agencyDashboard().then((response) => {
        if (response.code == 200) {
          this.dashboardInfo = response.data
        }
      })
    },
    getVersionDashboard() {
      versionDashboard().then((response) => {
        if (response.code == 200) {
          this.versionDashboardInfo = response.data
        }
      })
    },
    getUserInfo() {
      this.$store.dispatch("GetUserInfo").then((res) => {
        this.userInfo = res.data;
        if(this.userInfo.adminAgencyId)
        getAgencyUser(this.userInfo.adminAgencyId).then(res => {
            this.agencyUser = res.data
        })
      });
    },
    async logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push({path:"/login"});
        })
      })
    },
  }
};
</script>
<style lang="scss" scoped>
  .header-wrapper{
    width:100%;
    text-align: center;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(51,45,64, .1);
  }
  .header-container{
    position: relative;
    display: inline-flex;;
    align-items: center;
    justify-content: space-between;
    width:  1588px;
    z-index: 100;
    font-size: 13px;
    height: 60px;
    color: #2b3441;
    .header-breadcrumb{
      display: inline-flex;
      display: -webkit-flex;
      align-items: center;
      .private-home-link{
        cursor: pointer;
        color: rgba(43,52,65,.6);
        font-size: 18px;
        font-weight: 500;
        .home-icon{
          display: inline-block;
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          margin-right: 8px;
          border-radius: 50%;
          background: #f5f5f7;
        }
      }
      .separator{
        margin: 0 11px 0 17px;
      }
      .headerNow{
        font-size: 18px;
        font-weight: 500;
      }
    }
    .header-actions {
      display: flex;
      align-items: center;
      height: 100%;
      overflow: hidden;
      .avatar-container {
        height: 36px;
        .avatar-wrapper {
          height: 36px;
          position: relative;
          .user-avatar {
            cursor: pointer;
            width: 36px;
            height: 36px;
            border-radius: 18px;
          }
          .el-icon-caret-bottom {
            cursor: pointer;
            position: absolute;
            right: -18px;
            top: 12px;
            font-size: 12px;
          }
        }
      }
    }
  }
  .agency-left{
    background-color:white;
    border-radius:4px;
    .tab-title{
      margin: 0;
      padding: 20px 16px;
      li{
          width: 100%;
          text-align: left;
          cursor: pointer;
          height: 40px;
          line-height: 40px;
          margin: 0 0 8px;
          list-style: none;
          font-size: 14px;
          color: #333333;
          background-color: #FAFBFB;
          border-radius: 4px;
          font-weight: 500 !important;
        &.active{
            background: rgba(0, 92, 238, .08);
            color: #3476F0;
            position: relative;
            border-radius: 6px;
            font-weight: 500!important;
            &:after {
                content: '';
                position: absolute;
                width: 4px;
                height: 30px;
                top: 50%;
                left: 0;
                margin-top: -15px;
                background: rgba(0, 92, 238, .4);
                border-radius: 0 3px 3px 0;
            }
        }
        &:hover {
          background: rgba(0, 92, 238, .08);
          color: #3476F0;
          border-radius: 6px;
          font-weight: 500!important;
        }
        .tab-title-icon{
          width: 18px;
          height: 18px;
          margin-left: 10px;
          margin-right: 8px;
          vertical-align: -5px;
        }
      }
    }
  }
  .agency-right {
    width: auto;
    padding: 0;
    min-height: 220px;
    // border-left: 1px solid #e5e5e5;
    border-radius: 6px;
    box-sizing: border-box;
    flex-shrink: 1;
    flex-grow:1;
    overflow: hidden;
    // flex: 1;
    // -webkit-flex: 1;
    .tab-content{
      width: 100%;
      height: 100%;
      .agency-info{
        margin: 0;
        padding: 0;
        dt {
          display: inline-block;
          img{
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
        }
        dd{
          line-height: 48px;
          font-size: 14px;
          font-weight: 600;
          color: #394349;
          margin-left: 10px;
        }
      }
      .agency-total{
        list-style: none;
        margin: 0;
        padding: 0;
        h5{
          font-size: 16px;
          font-weight: 500;
          color: #2b3441;
          margin: 10px 0;
          padding: 0;
        }
        li {
          font-size: 15px;
          color: #2b3441;
          line-height: 32px;
        }
      }
      /*新版style*/
      .fx-corp-info-panel {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-color: white;
        margin-bottom: 16px;
        border-radius:4px;
        // padding: 10px 0;
        .fx-row-info-panel{
          display: flex;
          align-items: center;
          min-height: 80px;
          height:120px;
          .label {
            -webkit-box-flex: 0;
            -ms-flex: none;
            flex: none;
            // width: 130px;
            text-align: center;
            color: #5e6d82;
            font-size: 14px;
            .agencyLogo{
              width: 70px;
              height: 70px;
              margin-left:16px;
              margin-right:16px;
              vertical-align: middle;
            }
          }
          .content {
            // padding: 0 20px;
            .content-row{
              line-height: 80px;
              color: #232425;
              font-size: 16px;
            }
          }
          &:last-child {
            border-bottom: 0;
          }
        }
      }
      .fx-version-info-panel{
        .version-wrapper{
          background-color: white;
          border-radius:4px;
          margin-bottom: 16px;
        }
        .privilege-wrapper{
          background-color: white;
          border-radius:4px;
        }

        .label {
          color: #1f2d3d;
          font-weight: 500;
          font-size: 16px;
          line-height: 44px;
          padding-left: 26px;
          border-bottom: solid 1px #EFF1F8;
        }
        .content {
          position: relative;
          // margin: 30px;
          padding: 16px;
          box-sizing: border-box;
          .privilege-item{
            position: relative;
            display: inline-block;
            width: 32%;
            height: 170px;
            margin-right: 2%;
            margin-bottom: 2%;
            padding: 20px 20px 18px;
            vertical-align: middle;
            background: #fff;
            border-radius: 3px;
            border: 1px solid #e5e5e5;
            &:nth-child(3n) {
              margin-right: 0;
            }
            .name-wrapper {
              height: 22px;
              line-height: 22px;
              .name-text {
                margin-left: 5px;
                color: #1f2d3d;
                font-weight: 500;
                font-size: 16px;
              }
            }
            .progress-bar{
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              height: 10px;
              margin-top: 20px;
              margin-bottom: 10px;
              .progress-wrapper{
                position: relative;
                -webkit-box-flex: 1;
                -ms-flex: auto;
                flex: auto;
                height: 100%;
                overflow: hidden;
                border-radius: 5px;
                background: rgb(241, 241, 241);
                .value-bar {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  -webkit-transition: width .2s ease;
                  transition: width .2s ease;
                  border-top-right-radius: inherit;
                  border-bottom-right-radius: inherit;
                  background: rgb(36, 138, 249);
                }
              }
            }
            .content-wrapper{
              color: #5e6d82;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }

      .agency-panel-title{
        width:100%;
        color: #1f2d3d;
        font-weight: 500;
        font-size: 16px;
        line-height:44px;
        padding-left:26px;
        border-bottom: solid 1px #EFF1F8;
      }
      .fx-agency-info-panel{
        padding-bottom: 20px;
        background-color: white;
        // margin-bottom: 20px;
        border-radius:4px;
        .agency-chart-container{
          display: flex;
          margin: 22px 16px 0px 16px;
          box-sizing: border-box;
          flex-wrap: wrap;
          .agency-chart-item{
            text-align: center;
            font-weight: 400;
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            background-color:#F8F9FB;
            width: 25%;
            padding:20px 0px;
            margin-bottom: 8px;
            border-radius: 4px;
            .current-amount{
              font-size: 30px;
              color: #262a3e;
              margin-bottom: 10px;
            }
            .sum {
              padding-left: 2px;
              color: #b0b0b9;
              font-size: 14px;
            }
          }
        }
      }
    }

    .tab-content-bg{
      background-color:white;
      border-radius:4px;
    }
  }
</style>

