<template>
    <div class="app-role">

        <el-row :gutter="20" style="margin: 0">
            <div class="head-container fr" style="margin-bottom: 30px;">
                <el-button type="primary" icon="el-icon-plus" size="mini" @click="onAddRoleClick(1)">新增角色组</el-button>
            </div>
            <el-col :span="24" :xs="24" style="padding: 0">
                <div class="head-container">
                    <el-tree :data="treeOptions" :props="treeProps" :expand-on-click-node="false" :filter-node-method="filterNode" ref="tree" :default-expand-all="defaultExpandAll">
                        <span class="custom-tree-node" slot-scope="{ node, data }" @mouseenter="onRoleTreeMouseEnter(data)" @mouseleave="onRoleTreeMouseLeave(data)">
                            <span class="fl node-label">{{ node.label }}</span>
                            <span class="fr node-btn" v-show="data.showBtn">
                                <span @click="onAddRoleClick(data,2)" v-if="data.parentId == 0">添加角色</span>
                                <span type="text" size="mini" @click="onEditRoleClick(data)">修改</span>
                                <span type="text" size="mini" @click="onDeleteRoleClick(data)">删除</span>
                            </span>
                        </span>
                    </el-tree>
                </div>
            </el-col>
        </el-row>

        <!-- 新建角色组 -->
        <el-dialog :title="roleType == 1 ? '新建角色组' : '新建角色'" :visible.sync="openBuild" width="500px" append-to-body :before-close="onHandleClose">
            <el-form ref="roleGroup" :model="roleGroup" :rules="roleGroupRules" label-width="110px" @submit.native.prevent>
                <!--W3C标准规定，form表单中如果只有一个输入框，那么在该输入框中按下回车即代表提交表单，这就是触发页面刷新的原因。-->
                <el-form-item :label="roleType == 1 ? '角色组名称' : '角色名称'" prop="roleName">
                    <el-input v-model.trim="roleGroup.roleName" :placeholder="roleType == 1 ? '请输入角色组名称' : '请输入角色名称'" spellcheck="false" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSubmitClick">确 定</el-button>
                <el-button @click="OnCancelClick">取 消</el-button>
            </div>
        </el-dialog>

        <!-- 修改角色组、角色 -->
        <el-dialog :title="roleType == 1 ? '修改角色组' : '修改角色'" :visible.sync="openEdit" width="500px" append-to-body :before-close="onHandleEditClose">
            <el-form ref="roleGroup" :model="roleGroup" :rules="roleGroupRules" label-width="110px" @submit.native.prevent>
                <!--W3C标准规定，form表单中如果只有一个输入框，那么在该输入框中按下回车即代表提交表单，这就是触发页面刷新的原因。-->
                <el-form-item :label="roleType == 1 ? '角色组名称' : '角色名称'" prop="roleName">
                    <el-input v-model.trim="roleGroup.roleName" :placeholder="roleType == 1 ? '请输入角色组名称' : '请输入角色名称'" spellcheck="false" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onEditSubmitClick">确 定</el-button>
                <el-button @click="OnEditCancelClick">取 消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import { updateAgencyRole, agencyRoleList, deleteAgencyRole } from '@/api/system/agency'

export default {
  name: 'User',
  components: {},
  props: {
    agencyId: {
      type: Number
    }
  },
  data() {
    return {
      treeProps: {
        children: 'children',
        label: 'roleName'
      },
      treeOptions: [],
      roleGroup: {
        agencyRoleId: null,
        parentId: 0,
        roleName: null,
        sort: 0
      },
      roleGroupRules: {
        roleName: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.roleType == 1) {
                if (value == '' || !value) {
                  callback(new Error('请输入角色组名称'))
                } else {
                  callback()
                }
              } else {
                if (value == '' || !value) {
                  callback(new Error('请输入角色名称'))
                } else {
                  callback()
                }
              }
            },
            trigger: 'blur'
          },
          { max: 200, message: '最多支持20个字符', trigger: 'blur' }
        ]
      },
      defaultExpandAll: false, // 是否默认展开所有节点
      roleType: null, // 新建角色组==1or角色==2
      openBuild: false, // 新增角色组、角色 弹框
      openEdit: false, // 修改角色组、角色 弹框
      editData: {} // 修改角色组、角色 修改数据
    }
  },
  watch: {},
  created() {
    this.getTreeSelect()
  },
  methods: {
    // 角色下拉树
    getTreeSelect() {
      agencyRoleList().then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].showBtn = false
        }
        const treeList = response.data
        this.treeOptions = this.handleTree(treeList, 'agencyRoleId')
      })
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // tree树形控件 鼠标滑过、离开
    onRoleTreeMouseEnter(data) {
      this.$set(data, 'showBtn', true)
    },
    onRoleTreeMouseLeave(data) {
      this.$set(data, 'showBtn', false)
    },
    // 新增角色组、角色
    onAddRoleClick(data, type) {
      this.openBuild = true
      if (type == 2) {
        // 新增角色
        this.roleType = 2
        this.roleGroup.parentId = data.agencyRoleId
      } else {
        // 新增角色组时 需要重置数据
        this.roleGroup = {
          agencyRoleId: null,
          parentId: 0,
          roleName: null,
          sort: 0
        }
        this.roleType = 1
      }
    },
    onSubmitClick() {
      this.$refs['roleGroup'].validate((valid) => {
        if (valid) {
          const agencyRole = {
            agencyRoleId: this.roleGroup.agencyRoleId,
            parentId: this.roleGroup.parentId,
            roleName: this.roleGroup.roleName,
            sort: this.roleGroup.sort
          }
          updateAgencyRole(agencyRole).then((responese) => {
            if (responese.code == 200) {
              const message = (this.roleType = 2 ? '创建角色成功' : '创建角色组成功')
              this.msgSuccess(message)
              this.openBuild = false
              this.roleType = null
              this.getTreeSelect()
              this.resetForm()
            } else {
              this.msgError(response.msg)
            }
          })
        }
      })
    },
    onHandleClose() {
      this.openBuild = false
      this.resetForm()
    },
    OnCancelClick() {
      this.openBuild = false
      this.resetForm()
    },
    // 修改角色组、角色
    onEditRoleClick(data) {
      this.openEdit = true
      this.roleType = data.parentId == 0 ? 1 : 2
      this.editData = data
      this.roleGroup.roleName = this.editData.roleName
    },
    onEditSubmitClick() {
      this.$refs['roleGroup'].validate((valid) => {
        if (valid) {
          const agencyRole = {
            agencyRoleId: this.editData.agencyRoleId,
            parentId: this.editData.parentId,
            roleName: this.roleGroup.roleName,
            sort: this.editData.sort
          }
          updateAgencyRole(agencyRole).then((responese) => {
            if (responese.code == 200) {
              const message = (this.roleType = 2 ? '修改角色成功' : '修改角色组成功')
              this.msgSuccess(message)
              this.openEdit = false
              this.roleType = null
              this.getTreeSelect()
              this.resetForm()
            } else {
              this.msgError(response.msg)
            }
          })
        }
      })
    },
    onDeleteRoleClick(data) {
      const _this = this
      this.$confirm('确认删除该角色吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(()=>{
          deleteAgencyRole(data.agencyRoleId).then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })

            let idx = 0
            if (data.parentId == 0) {
              idx = _this.treeOptions.findIndex((x) => x.agencyRoleId == data.agencyRoleId)
              _this.treeOptions.splice(idx, 1)
            } else {
              idx = _this.treeOptions.find((x) => x.agencyRoleId == data.parentId).children.findIndex((x) => x.agencyRoleId == data.agencyRoleId)
              _this.treeOptions.splice(idx, 1)
            }
          }).catch(()=>{
            this.msgError('角色删除失败')
          })
        })
        .catch(function() {})
    },
    onHandleEditClose() {
      this.openEdit = false
      this.resetForm()
    },
    OnEditCancelClick() {
      this.openEdit = false
      this.resetForm()
    },
    resetForm() {
      if (this.$refs['roleGroup']) {
        this.$refs['roleGroup'].resetFields()
      }
    }
  }
}
</script>
<style lang="scss">
.app-role {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
}
.head-container {
    .el-tree-node__content {
        height: 50px;
        border-bottom: 1px solid #e5e5e5;
        .custom-tree-node {
            width: 100%;
            height: 50px;
            line-height: 50px;
            .node-label {
                font-size: 13px;
                font-weight: 400;
                color: #394349;
            }
            .node-btn {
                font-size: 12px;
                font-weight: 400;
                color: #3476F0;
                span {
                    border-right: 1px solid #e5e5e5;
                    padding: 0 20px;
                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }
    }
}
</style>
